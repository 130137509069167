import { web3Constants } from "../constants";

import { getChainConnect } from "../utils/lib";

const initialState = {
  is_processing: false,
  step: null,
};

export function buyOnETH(state = initialState, action) {
  switch (action.type) {
    case web3Constants.UPDATE_STEP_BUY_IN_ETH:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
}
