import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/lang/en.json";
import translationVI from "./locales/lang/vi.json";
import translationTH from "./locales/lang/th.json";
import translationRU from "./locales/lang/ru.json";
import translationPT from "./locales/lang/pt.json";
import translationES from "./locales/lang/es.json";
import translationJP from "./locales/lang/jp.json";
import translationKO from "./locales/lang/ko.json";
import translationFR from "./locales/lang/fr.json";
import translationID from "./locales/lang/id.json";
import translationAR from "./locales/lang/ar.json";
import translationHI from "./locales/lang/hi.json";
import translationDE from "./locales/lang/de.json";
import translationPH from "./locales/lang/ph.json";
import translationCN from "./locales/lang/cn.json";


import LanguageDetector from "i18next-browser-languagedetector";

// the translations
const resources = {
    en: {translation: translationEN},
    vi: {translation: translationVI},
    th: {translation: translationTH},
    ru: {translation: translationRU},
    pt: {translation: translationPT},
    es: {translation: translationES},
    jp: {translation: translationJP},
    ko: {translation: translationKO},
    fr: {translation: translationFR},
    id: {translation: translationID},
    ar: {translation: translationAR},
    hi: {translation: translationHI},
    de: {translation: translationDE},
    ph: {translation: translationPH},
    cn: {translation: translationCN},
};

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
