import _ from "lodash";
import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import * as actions from "../../actions";
import {
  numberWithCommas,
  showNumberOrDash,
  getNumberEther,
  roundNumberWithDecimal,
} from "../../utils/lib";
import i18next from "i18next";

const EarnApy = () => {
  const dispatch = useDispatch();
  const { config, stakeStats } = useSelector(
    (state) => state.w3Stake,
    shallowEqual
  );

  const [
    enabledStaking,
    enabledWithdrawZc,
    defaultApy,
    numberOfRounds,
    secondsPerRound,
    unstakeTimelock,
    presaleStartAt,
    currentApy,
  ] = config;

  useEffect(() => {
    if (!stakeStats) {
      dispatch(actions.loadStakeStats());
    }
  }, []);

  const TOTAL_ZC_SUPPLY = 180 * 1000000;

  const showPercentStake = () => {
    let totalStake = _.get(stakeStats, "total_staked", 0);
    if (!totalStake) return "--";
    totalStake = (getNumberEther(totalStake) / TOTAL_ZC_SUPPLY) * 100;
    console.log({ totalStake });
    return roundNumberWithDecimal(totalStake, 6);
  };

  return (
    <section className="earn-presale-section">
      <div className="img-card">&nbsp;</div>
      <div className="container">
        <div className="row">
          <div className="col-xxl-5 col-xl-6 col-lg-7 card-content">
            <h1 className="mb-4 text-uppercase text-white text-center text-lg-start">
              {i18next.t("earn_apy")}
            </h1>
            <div className="row mb-2">
              <div className="col-lg-7">
                <p className="fw-medium text-white">
                  {i18next.t("earn_apy_des")}
                </p>
              </div>
              <div className="col-lg-5 text-lg-end">
                <a
                    href="https://docs.zapclash.com/staking/introduction-to-staking" target="_blank"
                    className="btn btn-polygon-primary btn-polygon-primary-md"
                >
                  <span>{i18next.t("learn_more")}</span>
                </a>
              </div>
              <div className="col-12 mt-xl-5 mt-3">
                <div className="card card-polygon-list">
                  <ul className="list-group">
                    <li className="list-group-item active">
                      <p>{i18next.t("est_rewards")}</p>
                      <b>{currentApy}%</b>
                      <small className="ms-2">APR</small>
                    </li>
                    <li className="list-group-item">
                      <p>{i18next.t("total_staked")}</p>
                      <b>
                        {/* {showNumberOrDash(
                          _.get(stakeStats, "total_staked", 0),
                          true
                        )} */}
                        --
                      </b>
                      <small className="ms-2">ZC</small>
                    </li>
                    <li className="list-group-item">
                      <p>{i18next.t("total_stakers")}</p>
                      <b>
                        {showNumberOrDash(
                          _.get(stakeStats, "zc_sold_count", 0)
                        )}
                      </b>
                    </li>
                    <li className="list-group-item">
                      <p>{i18next.t("percent_staked")}</p>
                      {/* <b>{showPercentStake()}</b> */}
                      <b>{showNumberOrDash(_.get(stakeStats, "total_zc_sold", 0), 1)}</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EarnApy;
