import i18next from "i18next";
import React from "react";

const BurningMechanism = () => {
  return (
    <section className="burning-mechanism-section position-relative">
      {/* Decoration */}
      <div className="card-polygon-decoration top"></div>
      <div className="card-polygon-decoration right"></div>
      <div className="card-polygon-decoration bottom"></div>
      <div className="container">
        <h1 className="display-3 text-uppercase text-white text-center">
          {i18next.t("burning_mechanism")}
        </h1>
        <div className="card card-transparent">
          <ul className="list-group list-group-flush">
            <li className="list-group-item mb-5">
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  id="check_label"
                  className="form-check-input"
                  defaultChecked={true}
                />
                <label
                  title=""
                  htmlFor="check_label"
                  className="form-check-label"
                >
                  {i18next.t("bm_des_1")}
                </label>
              </div>
            </li>
            <li className="list-group-item mb-5 mx-5">
              <div className="row row-cols-1 row-cols-lg-3">
                <div className="col d-flex justify-content-center">
                  <div className="card card-honey">
                    <div className="card-body">
                      {i18next.t("bm_des_1_1")}
                    </div>
                  </div>
                </div>
                <div className="col d-flex justify-content-center">
                  <div className="card card-honey">
                    <div className="card-body">
                      {i18next.t("bm_des_1_2")}
                    </div>
                  </div>
                </div>
                <div className="col d-flex justify-content-center">
                  <div className="card card-honey">
                    <div className="card-body">
                      {i18next.t("bm_des_1_3")}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  id="check_label"
                  className="form-check-input"
                  defaultChecked={true}
                />
                <label
                  title=""
                  htmlFor="check_label"
                  className="form-check-label"
                >
                  {i18next.t("bm_des_2")}
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
export default BurningMechanism;
