import { Image, Row, Col } from "react-bootstrap";
import React from "react";
import i18next from "i18next";
export default function Footer() {
  return (
    <>
      {/* Main footer */}
      <footer>
        <ul className="nav justify-content-center nav-social">
          <li className="nav-item">
            <a href="https://twitter.com/ZapClashGameFi" target="_blank" className="nav-link">
              <Image alt="ico-x" src="/images/icons/ico-x.png" />
            </a>
          </li>
          <li className="nav-item">
            <a href="https://t.me/zapclash_official" target="_blank" className="nav-link">
              <Image alt="ico-plane" src="/images/icons/ico-plane.png" />
            </a>
          </li>
        </ul>
        <ul className="nav justify-content-center pb-lg-1 pb-0">
          <li className="nav-item">
            <a href="https://docs.zapclash.com/privacy-policy" target="_blank" className="nav-link px-lg-5 px-2">
              {i18next.t("privacy_notice")}
            </a>
          </li>
          <li className="nav-item">
            <a href="https://docs.zapclash.com/disclaimer" target="_blank" className="nav-link px-lg-5 px-2">
              {i18next.t("disclaimer")}
            </a>
          </li>
          <li className="nav-item">
            <a href="https://docs.zapclash.com/" target="_blank" className="nav-link px-lg-5 px-2">
              {i18next.t("whitepaper")}
            </a>
          </li>
        </ul>
        <p className="text-center">
          &copy; 2024 Zap Clash - {i18next.t("all_rights_reserved")} - contact@zapclash.com
        </p>
      </footer>
    </>
  );
}
