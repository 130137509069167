import { web3Constants } from "../constants";

const initialState = {
  contract: null,
  contractAccount: null,
  config: [true, false, "79", "9", "604800", "604800", "1705062452", "325"],
  stakeInfo: null,
  stakeStats: null,
  stakeLeaderboard: null,
};

export function w3Stake(state = initialState, action) {
  switch (action.type) {
    case web3Constants.INIT_CONTRACT_STAKING:
      return {
        ...state,
        ...action,
      };

    case web3Constants.WEB3_INIT_STAKE_CONFIG:
      return {
        ...state,
        config: action.config,
      };

    case web3Constants.WEB3_INIT_STAKE_ACCOUNT:
      return {
        ...state,
        contractAccount: action.contractAccount,
        stakeInfo: action.stakeInfo,
      };

    case web3Constants.FETCH_STAKE_STATS_SUCCESS:
      return {
        ...state,
        stakeStats: action.stakeStats,
      };

    case web3Constants.FETCH_STAKE_LEADERBOARD_SUCCESS:
      return {
        ...state,
        stakeLeaderboard: action.data,
      };

    default:
      return state;
  }
}
