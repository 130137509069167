import _ from "lodash";
import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Image } from "react-bootstrap";
import { getNumberUSDT } from "../../utils/lib";
import * as actions from "../../actions";

import { useTranslation } from "react-i18next";

const FundProcessing = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data } = useSelector((state) => state.presaleReducer, shallowEqual);

  useEffect(() => {}, []);

  const FUND_RAISE = process.env.REACT_APP_ZC_FUND_RAISED;

  function showNumber(number) {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  }

  function getFunded() {
    const funded = _.get(data, "total_fund_raised.total", 0);
    if (!funded) return 0;
    return getNumberUSDT(funded, true, 0);
  }

  function getPercent() {
    const funded = getFunded();
    if (!funded) return 0;
    let percent = Number.parseInt((funded / FUND_RAISE) * 100);
    return percent;
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-xl-8 mx-lg-auto">
          <div
            className="progress"
            role="progressbar"
            aria-label="Warning example"
            aria-valuenow={getPercent()}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar text-end"
              style={{ width: getPercent() + "%" }}
            ></div>
            <div
              className="progress-bar text-start"
              style={{
                margin: 0,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              // style={{ width: 100 - getPercent() + "%" }}
            >
              <div className="progress-content px-2">
                <Image
                  src="/images/icons/ico-USDT.png"
                  alt="ico-usdt"
                  width="36"
                />
                <span className="me-2">USDT {t("raised")}:</span>
                <span>${showNumber(getFunded())}</span>
                <span className="mx-2">/</span>
                <span className="text-truncate" title="">
                  ${showNumber(FUND_RAISE)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FundProcessing;
