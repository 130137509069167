import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Image } from "react-bootstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  getNumberUSDT,
  getNumberEther,
  numberWithCommas,
  calculateTimeLeft,
} from "../../utils/lib";

import i18next from "i18next";
import * as Consts from "../../consts";

const Welcome = ({ nextRound, onCountdownZero }) => {
  const { rounds, roundIdx } = useSelector(
    (state) => state.w3PreSale,
    shallowEqual
  );

  let [nextIdxRound, targetDate, nextEndAt, nextPrice] = nextRound;

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft(targetDate);
      setTimeLeft(newTimeLeft);

      if (
        newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === 0 &&
        newTimeLeft.seconds === 0
      ) {
        // Countdown reached zero, trigger the callback
        if (onCountdownZero) {
          onCountdownZero();
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <section className="welcome-section">
      <ul className="nav flex-column nav-social-sticky">
        <li className="nav-item">
          <a
            // href="https://twitter.com/ZapClashGameFi"
            // target="_blank"
            onClick={() => {
              Consts.handlerClickURL(
                Consts.GA_EVENTS.btn_twitter_leftside,
                "https://twitter.com/ZapClashGameFi",
                true
              );
            }}
            className="nav-link"
          >
            <Image alt="ico-x" src="/images/icons/social/ico-x.png" />
          </a>
        </li>
        <li className="nav-item">
          <a
            // href="https://t.me/zapclash_official"
            // target="_blank"
            onClick={() => {
              Consts.handlerClickURL(
                Consts.GA_EVENTS.btn_tele_leftside,
                "https://t.me/zapclash_official",
                true
              );
            }}
            className="nav-link"
          >
            <Image alt="ico-plane" src="/images/icons/social/ico-tele.png" />
          </a>
        </li>
      </ul>
      <video
        autoPlay
        loop
        muted
        playsInline
        poster="/images/welcome/bg-banner.jpg"
      >
        <source src="/video/banner.webm" type="video/webm" />
        {/* <source src="/video/banner.mp4" type="video/mp4" /> */}
      </video>
      {nextPrice ? (
        <Container>
          {/* Count Down */}
          <Card className="card-transparent">
            <Row className="col-xl-7 col-lg-9 mx-auto">
              <div className="col">
                <h1 className="font-buchanan text-uppercase text-center text-white text-head">
                  {!roundIdx
                    ? i18next.t("coundown_to_presale")
                    : `${i18next.t("next_price")} ${getNumberUSDT(
                        nextPrice
                      )} ${i18next.t("in")}`}
                </h1>
              </div>
            </Row>
            <Row className="col-xl-7 col-lg-9 mb-4 mx-auto">
              {/* Days */}
              <Col>
                <div className="card card-squares card-squares-lg">
                  <div className="card-body text-center">
                    <h1 className="mb-0 text-white">{timeLeft.days}</h1>
                    <small className="text-uppercase text-primary-lightest">
                      {i18next.t("days")}
                    </small>
                  </div>
                </div>
              </Col>
              {/* Hours */}
              <Col>
                <div className="card card-squares card-squares-lg">
                  <div className="card-body text-center">
                    <h1 className="mb-0 text-white">{timeLeft.hours}</h1>
                    <small className="text-uppercase text-primary-lightest">
                      {i18next.t("hours")}
                    </small>
                  </div>
                </div>
              </Col>
              {/* Minutes */}
              <Col>
                <div className="card card-squares card-squares-lg">
                  <div className="card-body text-center">
                    <h1 className="mb-0 text-white">{timeLeft.minutes}</h1>
                    <small className="text-uppercase text-primary-lightest">
                      {i18next.t("minutes")}
                    </small>
                  </div>
                </div>
              </Col>
              {/* Seconds */}
              <Col>
                <div className="card card-squares card-squares-lg">
                  <div className="card-body text-center">
                    <h1 className="mb-0 text-white">{timeLeft.seconds}</h1>
                    <small className="text-uppercase text-primary-lightest">
                      {i18next.t("seconds")}
                    </small>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Container>
      ) : (
        ""
      )}
    </section>
  );
};
export default Welcome;
