import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal, Button, Image } from "react-bootstrap";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import * as actions from "../../actions";
import { web3Constants } from "../../constants";

import * as Consts from "../../consts";

import {
  numberWithCommas,
  getNumberEther,
  showNumberOrDash,
  roundNumberWithDecimal,
  showAliasWalletAddress,
  getChainConnect,
  validateNumberInput,
} from "../../utils/lib";

const StakeForApy = ({ currentApy }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { presaleBalance } = useSelector(
    (state) => state.presaleReducer,
    shallowEqual
  );
  const [amount, setAmount] = useState("");

  const { is_processing, step, waiting } = useSelector(
    (state) => state.buyOnETH,
    shallowEqual
  );

  const [ttlPending, setTtlPending] = useState(null);
  const [pendingTime, setPendingTime] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!pendingTime) return;
      const now_ms = Date.now();
      const delta = Math.floor(pendingTime - now_ms / 1000);
      // console.log({pendingTime, now_ms, delta});
      // console.log("delta", delta);
      setTtlPending(delta);
      if (delta < 0) {
        setPendingTime(null);
        dispatch(actions.loadPresaleBalance());
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    setMaxAmount();
    setPendingTime(_.get(presaleBalance, "latest_pending_end_at"));
  }, [presaleBalance]);

  const handleShow = () => {
    setShowModal(true);
    console.log("handleShow Clicked");
  };
  const handleClose = () => setShowModal(false);

  const handleInputTokenChange = (e) => {
    let value = e.target.value;
    if (!validateNumberInput(value)) return;
    if (_.toNumber(value) > getNumberEther(presaleBalance.balance, true, 2)) {
      value = getNumberEther(presaleBalance.balance, true, 2);
    }
    setAmount(value);
  };

  const handleStakeClick = async () => {
    if (!amount) {
      dispatch(actions.alertActions.error("Please input amount Token"));
      return;
    }

    Consts.trackingEventGA(Consts.GA_EVENTS.btn_stake_sp, {
      screen_name: "staking",
    });

    await dispatch({
      type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
      step: "Loading....",
      is_processing: true,
    });
    await dispatch(actions.stakeForApy(amount))
      .then(() => {
        dispatch(actions.alertActions.success("Transaction successful"));
        setAmount("");
        dispatch({
          type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
          is_processing: false,
        });
        setTimeout(() => {
          dispatch(actions.loadPresaleBalance());
        }, 1000);
        Consts.trackingEventGA(`${Consts.GA_EVENTS.btn_stake_sp}_success`, {
          screen_name: "staking",
        });
      })
      .catch((e) => {
        dispatch(actions.alertActions.error(e.message));
        dispatch({
          type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
          is_processing: false,
        });
        setTimeout(() => {
          dispatch(actions.loadPresaleBalance());
        }, 1000);

        Consts.trackingEventGA(`${Consts.GA_EVENTS.btn_stake_sp}_error`, {
          screen_name: "staking",
          error: e.message,
        });
      });
  };

  const setMaxAmount = () => {
    let value = getNumberEther(_.get(presaleBalance, "balance"), true, 2) || 0;
    if (!value) return;
    setAmount(value);
  };

  return (
    <div>
      <button
        type="button"
        className={`btn btn-polygon-primary btn-md me-md-2 mb-md-0 mb-2 ${
          !_.toNumber(_.get(presaleBalance, "balance")) &&
          !_.toNumber(_.get(presaleBalance, "balance_pending"))
            ? "disabled"
            : ""
        }`}
        onClick={handleShow}
      >
        Stake for {currentApy}% APY
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg" centered={true}>
        <Modal.Header closeButton>
          <h1 className="modal-title fs-3">Stake</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="card-input-polygon">
            <div className="d-flex justify-content-between">
              <label
                htmlFor="stake_amount-url"
                className="form-label text-uppercase"
              >
                <b>Amount</b>
              </label>
              <div>
                <span className="form-label-secondary mx-4 text-uppercase">
                  <b>Pending:</b>
                  <span className="px-1">
                    {showNumberOrDash(
                      _.get(presaleBalance, "balance_pending"),
                      true,
                      2
                    )}{" "}
                    ZC
                  </span>
                  {ttlPending && ttlPending > 0 && (
                    <span className="px-1">({ttlPending})</span>
                  )}
                </span>
                <span className="form-label-secondary text-uppercase">
                  <b>Available:</b>
                  <span className="px-1">
                    {showNumberOrDash(
                      _.get(presaleBalance, "balance"),
                      true,
                      2
                    )}{" "}
                    ZC
                  </span>
                </span>
              </div>
            </div>
            <div className="input-group d-flex align-items-center">
              <input
                type="text"
                className="form-control"
                id="stake_amount"
                value={amount}
                onChange={handleInputTokenChange}
                placeholder={showNumberOrDash(
                  _.get(presaleBalance, "balance"),
                  true,
                  2
                )}
              />
              <span
                className="input-group-text input-group-max"
                id="value_max"
                onClick={setMaxAmount}
              >
                Max
              </span>
              <span className="input-group-text pe-0" id="value_coin">
                <Image src="/images/icons/ico-ZC.png" alt="ico-zc" width="32" />
                <span>ZC</span>
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn btn-polygon-primary ${
              (!amount || amount < 1) && "disabled"
            }`}
            onClick={handleStakeClick}
          >
            {!is_processing ? `Stake` : `${step}`}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StakeForApy;
