const refixTitle = "Zapclash";

export const routerHeaderTitle = {
  title: refixTitle,
  homepage: "Zap Clash is a card-based gamefi built on the Ethereum",
  homepage_description: "Zap Clash is a revolutionary gamefi project, seamlessly merging the excitement of card-based gameplay with the power of the Ethereum blockchain",
  staking: "Stake with Zap Clash for lucrative token rewards",
  staking_description: "In Zap Clash, staking is a way for us to reward community members for their long-term thinking by allowing them to lock their ZC tokens",

};
