import { web3Constants } from "../constants";

const initialState = {
  contractUSDT: null,
  balance: 0,
};

export function w3Usdt(state = initialState, action) {
  switch (action.type) {
    case web3Constants.INIT_CONTRACT_USDT:
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
}
