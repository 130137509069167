import i18next from "i18next";

const FaQ = () => {
  return (
    <>
      {/*FREQUENTLY ASKED QUESTIONS*/}
      <section id="FAQ" className="faq-section" data-bs-spy="scroll"
               data-bs-target="#faq" data-bs-offset="0" data-bs-smooth-scroll="true">
        <div className="container">
          <h1 className="display-3 text-uppercase text-center">
            {i18next.t("faq")}
          </h1>
          <div className="accordion accordion-flush" id="accordion_faq">
            {/*FAQ 1*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_1" aria-expanded="true" aria-controls="collapse_1">
                  01/ {i18next.t("question_1")}
                </button>
              </h2>
              <div id="collapse_1" className="accordion-collapse collapse show" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("anwser_1")}
                </div>
              </div>
            </div>
            {/*FAQ 2*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_2" aria-expanded="false" aria-controls="collapse_2">
                  02/ {i18next.t("question_2")}
                </button>
              </h2>
              <div id="collapse_2" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("anwser_2")}
                </div>
              </div>
            </div>
            {/*FAQ 3*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_3" aria-expanded="false" aria-controls="collapse_3">
                  03/ {i18next.t("question_3")}
                </button>
              </h2>
              <div id="collapse_3" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("answer_3")}
                  <ul className="list-group list-group-flush d-none">
                    <li className="list-group-item mb-lg-3 mb-2">
                      <div className="form-check form-check-inline">
                        <input type="checkbox" id="check_label_faq_1" className="form-check-input" defaultChecked={true} />
                        <label title="Purchase $ZC tokens at a discounted price." htmlFor="check_label_faq_1" className="form-check-label">Purchase $ZC tokens at a discounted price.</label>
                      </div>
                    </li>
                    <li className="list-group-item mb-lg-3 mb-2">
                      <div className="form-check form-check-inline">
                        <input type="checkbox" id="check_label_faq_2" className="form-check-input" defaultChecked={true} />
                        <label title="Stake and receive high APY." htmlFor="check_label_faq_2" className="form-check-label">Stake and receive high APY.</label>
                      </div>
                    </li>
                    <li className="list-group-item mb-lg-3 mb-2">
                      <div className="form-check form-check-inline">
                        <input type="checkbox" id="check_label_faq_3" className="form-check-input" defaultChecked={true} />
                        <label title="Chance to receive limited premium boxes." htmlFor="check_label_faq_3" className="form-check-label">Chance to receive limited premium boxes.</label>
                      </div>
                    </li>
                    <li className="list-group-item mb-lg-3 mb-2">
                      <div className="form-check form-check-inline">
                        <input type="checkbox" id="check_label_faq_4" className="form-check-input" defaultChecked={true} />
                        <label title="Presale participants have priority for early NFT purchases." htmlFor="check_label_faq_4" className="form-check-label">Presale participants have priority for early NFT purchases.</label>
                      </div>
                    </li>
                    <li className="list-group-item mb-lg-3 mb-2">
                      <div className="form-check form-check-inline">
                        <input type="checkbox" id="check_label_faq_5" className="form-check-input" defaultChecked={true} />
                        <label title="No tokens allocated for private or seed funds. All tokens in the market are distributed to the community, offering a x100 investment opportunity for early investors." htmlFor="check_label_faq_5" className="form-check-label">
                          No tokens allocated for private or seed funds. All tokens in the market are distributed to the community, offering a x100 investment opportunity for early investors.</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*FAQ 4*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_4" aria-expanded="false" aria-controls="collapse_4">
                  04/ {i18next.t("question_4")}
                </button>
              </h2>
              <div id="collapse_4" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("answer_4")}
                </div>
              </div>
            </div>
            {/*FAQ 5*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_5" aria-expanded="false" aria-controls="collapse_5">
                  05/ {i18next.t("question_5")}
                </button>
              </h2>
              <div id="collapse_5" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("answer_5")}
                </div>
              </div>
            </div>
            {/*FAQ 6*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_6" aria-expanded="false" aria-controls="collapse_6">
                  06/ {i18next.t("question_6")}
                </button>
              </h2>
              <div id="collapse_6" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("answer_6")}
                </div>
              </div>
            </div>
            {/*FAQ 7*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_7" aria-expanded="false" aria-controls="collapse_7">
                  07/ {i18next.t("question_7")}
                </button>
              </h2>
              <div id="collapse_7" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("answer_7")}
                </div>
              </div>
            </div>
            {/*FAQ 8*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_8" aria-expanded="false" aria-controls="collapse_8">
                  08/ {i18next.t("question_8")}
                </button>
              </h2>
              <div id="collapse_8" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("answer_8")}
                </div>
              </div>
            </div>
            {/*FAQ 9*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_9" aria-expanded="false" aria-controls="collapse_9">
                  09/ {i18next.t("question_9")}
                </button>
              </h2>
              <div id="collapse_9" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  <span>{i18next.t("answer_9")}</span>
                  <a href="https://docs.zapclash.com/presale/stages-and-milestones">https://docs.zapclash.com/presale/stages-and-milestones</a>
                </div>
              </div>
            </div>
            {/*FAQ 10*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_10" aria-expanded="false" aria-controls="collapse_10">
                  10/ {i18next.t("question_10")}
                </button>
              </h2>
              <div id="collapse_10" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("answer_10")}
                </div>
              </div>
            </div>
            {/*FAQ 11*/}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_11" aria-expanded="false" aria-controls="collapse_11">
                  11/ {i18next.t("question_11")}
                </button>
              </h2>
              <div id="collapse_11" className="accordion-collapse collapse" data-bs-parent="#accordion_faq">
                <div className="accordion-body">
                  {i18next.t("answer_11")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default FaQ;
