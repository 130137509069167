import React from "react";
import i18next from "i18next";
import {Image} from "react-bootstrap";
import * as Consts from "../../consts";

const GamePlay = () => {
  return (
      <div>
        {/* // GAMEPLAY */}
        <section id="gameplay" className="gameplay-section position-relative"
                 data-bs-spy="scroll" data-bs-target="#gameplay" data-bs-offset="0" data-bs-smooth-scroll="true"
        >
          {/* Decoration */}
          <div className="card-polygon-decoration right d-none"></div>
          <div className="container">
            <div className="row mx-lg-auto">
              <div className="col-lg-6">
                <h1 className="display-3 text-uppercase text-center text-lg-start">
                  {i18next.t("gameplay")}
                </h1>
                <div className="card card-transparent">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item mb-3">
                      <div className="form-check form-check-inline">
                        <input
                            type="checkbox"
                            id="check_label"
                            className="form-check-input"
                            defaultChecked={true}
                        />
                        <label
                            title=""
                            htmlFor="check_label"
                            className="form-check-label"
                        >
                          {i18next.t("gameplay_des_1")}
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item mb-3">
                      <div className="form-check form-check-inline">
                        <input
                            type="checkbox"
                            id="check_label"
                            className="form-check-input"
                            defaultChecked={true}
                        />
                        <label
                            title=""
                            htmlFor="check_label"
                            className="form-check-label"
                        >
                          {i18next.t("gameplay_des_2")}
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item mb-3">
                      <div className="form-check form-check-inline">
                        <input
                            type="checkbox"
                            id="check_label"
                            className="form-check-input"
                            defaultChecked={true}
                        />
                        <label
                            title=""
                            htmlFor="check_label"
                            className="form-check-label"
                        >
                          {i18next.t("gameplay_des_3")}
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="form-check form-check-inline">
                        <input
                            type="checkbox"
                            id="check_label"
                            className="form-check-input"
                            defaultChecked={true}
                        />
                        <label
                            title=""
                            htmlFor="check_label"
                            className="form-check-label"
                        >
                          {i18next.t("gameplay_des_4")}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col text-center d-none">
                <div className="parallax" id="parallax-card">
                  &nbsp;
                </div>
              </div>
              <div className="col card-iframe">
                <iframe src="https://www.youtube.com/embed/186py9-XvB4?si=vogl8lf2vmY7HleF"
                        title="YouTube Video Player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </div>
            </div>
          </div>
          <div className="gameplay-banner-section text-center">
            <Image src="/images/gameplay/gameplay_charc.png" className="gameplay-charc"
                   alt="gameplay_charc"
            />
            <div className="container-fluid gameplay-banner-mobile d-none">
              <div className="row row-cols-1 text-center g-0">
                <div className="col">
                  <Image src="/images/gameplay/gameplay_charc_4.png"
                         alt="gameplay_charc_4"
                  />
                </div>
                <div className="col">
                  <Image src="/images/gameplay/gameplay_charc_3.png"
                         alt="gameplay_charc_3"
                  />
                </div>
                <div className="col">
                  <Image src="/images/gameplay/gameplay_charc_2.png"
                         alt="gameplay_charc_2"
                  />
                </div>
                <div className="col">
                  <Image src="/images/gameplay/gameplay_charc_1.png"
                         alt="gameplay_charc_1"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
            id="featured"
            className="featured-section position-relative"
        >
          <div className="container">
            <h1 className="display-3 text-uppercase text-center">Featured In</h1>
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4 text-center align-items-center justify-content-center">
              {/* Logo Cryptonews */}
              <div className="col">
                <a href="#">
                  <Image src="/images/featured/ico_cryptonews.png" alt="ico_cryptonews" width={"70%"} />
                </a>
              </div>
              {/* Logo Techopedia */}
              <div className="col">
                <a href="#">
                  <Image src="/images/featured/ico_techopedia.png" alt="ico_techopedia" />
                </a>
              </div>
              {/* Logo Coinsniper */}
              <div className="col">
                <a href="#">
                  <Image src="/images/featured/ico_coinsniper.png" alt="ico_coinsniper" />
                </a>
              </div>
              {/* Logo Cryptototem */}
              <div className="col">
                <a href="#">
                  <Image src="/images/featured/ico_cryptototem.png" alt="ico_cryptototem" />
                </a>
              </div>
              {/* Logo Foundico */}
              <div className="col">
                <a href="#">
                  <Image src="/images/featured/ico_foundico.png" alt="ico_foundico" />
                </a>
              </div>
              {/* Logo Link */}
              <div className="col">
                <a href="#">
                  <Image src="/images/featured/ico_link.png" alt="ico_link" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
};
export default GamePlay;
