import n18next from "i18next";
import { Image } from "react-bootstrap";
import React from "react";

import * as Consts from "../../consts";

const Team = () => {
  return (
    <>
      {/*MEET OUR TEAM*/}
      <section
        id="team"
        className="team-section"
        data-bs-spy="scroll"
        data-bs-target="#team"
        data-bs-offset="0"
        data-bs-smooth-scroll="true"
      >
        <div className="container">
          <h1 className="display-3 text-uppercase text-center">
            {n18next.t("meet_our_team")}
          </h1>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-5 g-3 g-lg-2">
            {/* Person 01 */}
            <div className="col">
              <div className="card card-polygon-team">
                <div className="card-img-top">
                  <Image src="/images/team/1.png" alt="team_1" />
                </div>
                <div className="card-body text-center text-uppercase lh-1">
                  <div className="d-flex justify-content-center">
                    <a
                      // href="https://www.linkedin.com/in/longnguyen07/"
                      // target="_blank"
                      className="me-2"
                      onClick={() =>
                        Consts.handlerClickURL(
                          Consts.GA_EVENTS.btn_linkedin_ceo,
                          "https://www.linkedin.com/in/longnguyen07/",
                          true
                        )
                      }
                    >
                      <Image src="/images/team/in.png" alt="ico-in" />
                    </a>
                    <div>
                      <h4 className="card-title font-buchanan mb-0">
                        <span>Long Nguyen</span>
                      </h4>
                      <p className="card-text mb-0 fw-medium">CEO / Founder</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Person 02 */}
            <div className="col">
              <div className="card card-polygon-team">
                <div className="card-img-top">
                  <Image src="/images/team/tony.png" alt="team_2" />
                </div>
                <div className="card-body text-center text-uppercase lh-1">
                  <h4 className="card-title font-buchanan mb-0">Tony</h4>
                  <p className="card-text mb-0 fw-medium">Business</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card card-polygon-team">
                <div className="card-img-top">
                  <Image src="/images/team/2.png" alt="team_2" />
                </div>
                <div className="card-body text-center text-uppercase lh-1">
                  <h4 className="card-title font-buchanan mb-0">Alice</h4>
                  <p className="card-text mb-0 fw-medium">CMO</p>
                </div>
              </div>
            </div>
            {/* Person 03 */}
            <div className="col">
              <div className="card card-polygon-team">
                <div className="card-img-top">
                  <Image src="/images/team/3.png" alt="team_3" />
                </div>
                <div className="card-body text-center text-uppercase lh-1">
                  <h4 className="card-title font-buchanan mb-0">
                    Matthew Prass
                  </h4>
                  <p className="card-text mb-0 fw-medium">
                    Blockchain Tech Lead
                  </p>
                </div>
              </div>
            </div>
            {/* Person 04 */}
            <div className="col">
              <div className="card card-polygon-team">
                <div className="card-img-top">
                  <Image src="/images/team/4.png" alt="team_4" />
                </div>
                <div className="card-body text-center text-uppercase lh-1">
                  <h4 className="card-title font-buchanan mb-0">
                    Arbel Hakopian
                  </h4>
                  <p className="card-text mb-0 fw-medium">Head of Product</p>
                </div>
              </div>
            </div>
            {/* Person 05 */}
            <div className="col d-none">
              <div className="card card-polygon-team">
                <div className="card-img-top">
                  <Image src="/images/team/5.png" alt="team_5" />
                </div>
                <div className="card-body text-center text-uppercase lh-1">
                  <h4 className="card-title font-buchanan mb-0">Khang Duong</h4>
                  <p className="card-text mb-0 fw-medium">Artist Lead</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*YOUTUBE*/}
      <section
        id="youtube"
        className="youtube-section position-relative"
        data-bs-spy="scroll"
        data-bs-target="#youtube"
        data-bs-offset="0"
        data-bs-smooth-scroll="true"
      >
        <div className="card-polygon-decoration top"></div>
        <div className="card-polygon-decoration right"></div>
        <div className="container">
          <h1 className="display-3 text-uppercase text-white text-center">
            Youtube
          </h1>
          <h5 className="font-roboto fw-medium text-white mb-1">
            Youtube key opinion leaders
          </h5>
          <h5 className="font-roboto text-white mb-4">
            that are speaking about $ZC
          </h5>
          <div className="row g-3 mb-3 mb-lg-5">
            <div className="col-12 col-lg-4 col-xl-3">
              <div className="card px-4 border-0 bg-orange-light">
                <a
                  // href="https://www.youtube.com/results?search_query=zap+clash+gamefi"
                  onClick={() => {
                    Consts.handlerClickURL(
                      Consts.GA_EVENTS.btn_youtube,
                      "https://www.youtube.com/results?search_query=zap+clash+gamefi"
                      // true
                    );
                  }}
                >
                  <div className="mb-4">
                    <Image
                      src="/images/youtube/ico_youtube_influence.png"
                      alt="youtube_influence"
                      className="w-100"
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <Image
                      src="/images/youtube/ico_click.png"
                      alt="ico-click"
                    />
                    <p className="d-flex align-items-center text-uppercase mb-0 ps-2 fw-medium">
                      Click to view all on Youtube
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-8 col-xl-9">
              <div className="row row-cols-1 row-cols-lg-3 g-3">
                {/* Video 01 */}
                <div className="col">
                  <div className="card border-0 bg-transparent">
                    <a
                      // href="https://youtu.be/Ka4AI1kQKDI?feature=shared"
                      onClick={() => {
                        Consts.handlerClickURL(
                          Consts.GA_EVENTS.btn_youtube,
                          "https://youtu.be/Ka4AI1kQKDI?feature=shared"
                          // true
                        );
                      }}
                    >
                      <Image src="/images/youtube/01.png" alt="youtube_01" />
                    </a>
                  </div>
                </div>
                {/* Video 02 */}
                <div className="col">
                  <div className="card border-0 bg-transparent">
                    <a
                      // href="https://youtu.be/j5wJcJohKAE?feature=shared"
                      onClick={() => {
                        Consts.handlerClickURL(
                          Consts.GA_EVENTS.btn_youtube,
                          "https://youtu.be/j5wJcJohKAE?feature=shared"
                          // true
                        );
                      }}
                    >
                      <Image src="/images/youtube/02.png" alt="youtube_02" />
                    </a>
                  </div>
                </div>
                {/* Video 03 */}
                <div className="col">
                  <div className="card border-0 bg-transparent">
                    <a
                      // href="https://youtu.be/67jimHbi7RE?feature=shared"
                      onClick={() => {
                        Consts.handlerClickURL(
                          Consts.GA_EVENTS.btn_youtube,
                          "https://youtu.be/67jimHbi7RE?feature=shared"
                          // true
                        );
                      }}
                    >
                      <Image src="/images/youtube/03.png" alt="youtube_03" />
                    </a>
                  </div>
                </div>
                {/* Video 04 */}
                <div className="col">
                  <div className="card border-0 bg-transparent">
                    <a
                      // href="https://youtu.be/uRdUGkhrIc0?feature=shared"
                      onClick={() => {
                        Consts.handlerClickURL(
                          Consts.GA_EVENTS.btn_youtube,
                          "https://youtu.be/uRdUGkhrIc0?feature=shared"
                          // true
                        );
                      }}
                    >
                      <Image src="/images/youtube/04.png" alt="youtube_04" />
                    </a>
                  </div>
                </div>
                {/* Video 05 */}
                <div className="col">
                  <div className="card border-0 bg-transparent">
                    <a
                      // href="https://youtu.be/NIlnsbQET4Y?feature=shared"
                      onClick={() => {
                        Consts.handlerClickURL(
                          Consts.GA_EVENTS.btn_youtube,
                          "https://youtu.be/NIlnsbQET4Y?feature=shared"
                          // true
                        );
                      }}
                    >
                      <Image src="/images/youtube/05.png" alt="youtube_05" />
                    </a>
                  </div>
                </div>
                {/* Video 06 */}
                <div className="col">
                  <div className="card border-0 bg-transparent">
                    <a
                      //  href="https://youtu.be/zTQyadc8oKA?feature=shared"
                      onClick={() => {
                        Consts.handlerClickURL(
                          Consts.GA_EVENTS.btn_youtube,
                          "https://youtu.be/zTQyadc8oKA?feature=shared"
                          // true
                        );
                      }}
                    >
                      <Image src="/images/youtube/06.png" alt="youtube_06" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Team;
