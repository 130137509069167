import _ from "lodash";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Image } from "react-bootstrap";
import BtnConnect from "../account/BtnConnect";
import FundProcessing from "./FundProcessing";
import CountdownTimer from "./CountDownTimer";

import {
  getNumberEther,
  getNumberUSDT,
  numberWithCommas,
  roundNumberWithDecimal,
  validateNumberInput,
} from "../../utils/lib";

import { web3 as web3Utis } from "../../consts";
import { web3Constants } from "../../constants";
import * as actions from "../../actions";
import * as Consts from "../../consts";

const BuyOnETH = ({ currentRound, nextRound, handleCountdownZero }) => {
  const dispatch = useDispatch();

  const { contractPreSale, rateZCtoETH } = useSelector(
    (state) => state.w3PreSale,
    shallowEqual
  );

  const w3Usdt = useSelector((state) => state.w3Usdt, shallowEqual);

  const { balance_eth } = useSelector((state) => state.web3, shallowEqual);

  const { account } = useSelector((state) => state.web3, shallowEqual);

  const { is_processing, step, waiting } = useSelector(
    (state) => state.buyOnETH,
    shallowEqual
  );

  const [isUSDT, setIsUSDT] = useState(false);
  const [msg, setMsg] = useState("");

  const [amountETH, setAmountETH] = useState("");
  const [amountUSDT, setAmountUSDT] = useState("");
  const [amountToken, setAmountToken] = useState("");

  let [nround, startAt, endAt, price] = currentRound;
  let [nextNround, nextStartAt, nextEndAt, nextPrice] = nextRound;

  const nPrice = getNumberUSDT(price);
  const nZCtoETH = 10000; // => rateZCtoETH

  useEffect(() => {}, []);

  const ZCtoETH = (amount) => {
    if (!rateZCtoETH) return 0;
    if (!validateNumberInput(amount)) return 0;
    return roundNumberWithDecimal((amount * rateZCtoETH) / nZCtoETH, 4);
  };

  const ETHtoZC = (amount) => {
    if (!rateZCtoETH) return 0;
    if (!validateNumberInput(amount)) return 0;
    return roundNumberWithDecimal((amount * nZCtoETH) / rateZCtoETH, 0);
  };

  const handleInputETHChange = (e) => {
    let value = e.target.value;
    if (!validateNumberInput(value)) return;
    setAmountETH(value);
    setAmountToken(ETHtoZC(_.toNumber(value)));
  };

  const handleInputUSDTChange = (e) => {
    let value = _.toNumber(e.target.value);
    if (!validateNumberInput(value)) return;
    setAmountUSDT(value);
    setAmountToken(roundNumberWithDecimal(value / nPrice, 2));
  };

  const handleInputTokenChange = (e) => {
    let value = e.target.value;
    if (!validateNumberInput(value)) return;
    setAmountToken(value);
    setAmountETH(ZCtoETH(_.toNumber(value)));
    setAmountUSDT(roundNumberWithDecimal(_.toNumber(value) * nPrice, 2));
  };

  const handleSwitchToBuyUSDT = async (evt, value) => {
    setIsUSDT(value);
  };

  const clearForm = () => {
    setAmountETH("");
    setAmountUSDT("");
    setAmountToken("");
  };

  const handleBuyClick = async () => {
    Consts.trackingEventGA(Consts.GA_EVENTS.btn_buy);
    return isUSDT ? handleBuyInETHwUSDT() : handleBuyInETHwETH();
  };

  const handleBuyInETHwETH = async () => {
    console.log("handleBuyInETHwETH", amountToken);
    if (!amountToken) {
      dispatch(actions.alertActions.error("Please input amount Token"));
      return;
    }

    Consts.trackingEventGA(Consts.GA_EVENTS.buy_eth_eth);
    await dispatch({
      type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
      step: "Loading....",
      is_processing: true,
    });
    await dispatch(actions.buyAndStakeInEth(amountToken))
      .then(() => {
        dispatch(actions.alertActions.success("Transaction successful"));
        clearForm();
        dispatch({
          type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
          is_processing: false,
        });
        setTimeout(() => {
          dispatch(actions.initBalanceETH());
          dispatch(actions.initUSDTContract());
          // dispatch(loadInfoStakingGem());
          // dispatch(actions.instantiateLUSContracts());
        }, 1000);
        setTimeout(() => {
          dispatch(actions.loadFunding());
        }, 5000);

        Consts.trackingEventGA(`${Consts.GA_EVENTS.buy_eth_eth}_success`);
      })
      .catch((e) => {
        dispatch(actions.alertActions.error(e.message));
        dispatch({
          type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
          is_processing: false,
        });

        Consts.trackingEventGA(`${Consts.GA_EVENTS.buy_eth_eth}_fail`, {
          message: e.message,
        });
      });
  };

  const handleBuyInETHwUSDT = async () => {
    console.log("handleBuyInETHwUSDT", amountToken);
    if (!amountToken) {
      dispatch(actions.alertActions.error("Please input amount Token"));
      return;
    }

    Consts.trackingEventGA(Consts.GA_EVENTS.buy_eth_usdt);
    await dispatch({
      type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
      step: "Loading....",
      is_processing: true,
    });
    await dispatch(actions.buyAndStakeInEthwUSDT(amountToken, amountUSDT))
      .then(() => {
        dispatch(actions.alertActions.success("Transaction successful"));
        clearForm();
        dispatch({
          type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
          is_processing: false,
        });
        setTimeout(() => {
          dispatch(actions.initBalanceETH());
          dispatch(actions.initUSDTContract());
          // dispatch(loadInfoStakingGem());
          // dispatch(actions.instantiateLUSContracts());
        }, 1000);
        setTimeout(() => {
          dispatch(actions.loadFunding());
        }, 5000);
        Consts.trackingEventGA(`${Consts.GA_EVENTS.buy_eth_usdt}_success`);
      })
      .catch((e) => {
        dispatch(actions.alertActions.error(e.message));
        dispatch({
          type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
          is_processing: false,
        });
        Consts.trackingEventGA(`${Consts.GA_EVENTS.buy_eth_usdt}_fail`, {
          message: e.message,
        });
      });
  };

  return (
    <>
      <p>{i18next.t("choose_currency")}</p>
      {/* Choose Currency */}
      <div className="row mb-3">
        <div className="col-xl-5 mx-lg-auto">
          <div className="row">
            <div className="col text-end">
              <button
                className={`btn btn-polygon-third ${
                  !isUSDT ? "btn-active" : ""
                }`}
                onClick={(evt) => handleSwitchToBuyUSDT(evt, false)}
              >
                <Image
                  src="/images/icons/ico-ETH.png"
                  alt="ico-eth"
                  width="32"
                />
                <span>ETH</span>
              </button>
            </div>
            <div className="col text-start">
              <button
                className={`btn btn-polygon-third ${
                  isUSDT ? "btn-active" : ""
                }`}
                onClick={(evt) => handleSwitchToBuyUSDT(evt, true)}
              >
                <Image
                  src="/images/icons/ico-USDT.png"
                  alt="ico-usdt"
                  width="32"
                />
                <span>USDT</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ZC Convert */}
      <div className="row mb-3 mx-auto">
        <div className="col-xl-5 col-lg-6 col-md-9 mx-auto px-xl-5 px-0">
          <div className="card card-polygon-secondary text-primary">
            <div className="card-body">
              <h4 className="font-roboto mb-0 text-center">
                <span>1 $ZC</span>
                <span className="px-4">=</span>
                <b>${getNumberUSDT(price)}</b>
              </h4>
            </div>
          </div>
        </div>
      </div>
      {nextStartAt ? (
        <>
          <h2 className="font-roboto text-uppercase fw-medium mb-3">
            <span>
              {i18next.t("next_price")}
              <b className="text-primary px-1">${getNumberUSDT(nextPrice)}</b>
              {i18next.t("in")}
            </span>
          </h2>
          <CountdownTimer
            targetDate={nextStartAt}
            onCountdownZero={handleCountdownZero}
          />
        </>
      ) : (
        ""
      )}
      {/* ZC Pay/Receive */}
      <div className="row mb-4">
        <div className="col-xl-8 mx-lg-auto">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 gy-5">
            <div className="col text-start position-relative">
              {!isUSDT ? (
                <label className="form-label">
                  {i18next.t("you_pay", { 0: "$ETH" })}
                </label>
              ) : (
                <label className="form-label">
                  {i18next.t("you_pay", { 0: "$USDT" })}
                </label>
              )}
              <div className="card-input-polygon">
                <div className="input-group">
                  {!isUSDT ? (
                    <>
                      <span className="input-group-text">
                        <Image
                          src="/images/icons/ico-ETH.png"
                          alt="ico-eth"
                          width="32"
                        />
                        <span>ETH</span>
                      </span>
                      <input
                        type="text"
                        placeholder={balance_eth || 0}
                        className={`form-control text-end ${
                          msg ? "is-invalid" : ""
                        }`}
                        value={amountETH}
                        onChange={handleInputETHChange}
                      />
                    </>
                  ) : (
                    <>
                      <span className="input-group-text">
                        <Image
                          src="/images/icons/ico-USDT.png"
                          alt="ico-usdt"
                          width="32"
                        />
                        <span>USDT</span>
                      </span>
                      <input
                        type="text"
                        placeholder={numberWithCommas(
                          getNumberUSDT(w3Usdt.balance, true, 0)
                        )}
                        className={`form-control text-end ${
                          msg ? "is-invalid" : ""
                        }`}
                        value={amountUSDT}
                        onChange={handleInputUSDTChange}
                      />
                    </>
                  )}

                  {msg ? (
                    <div className="invalid-feedback fw-medium">
                      <span>Warning</span>
                      <span className="text-body">
                        {" "}
                        - You do not have enough ETH balance.
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <i className="ico ico-chevron-right"></i>
            </div>
            <div className="col text-start">
              <label className="form-label">
                {i18next.t("you_receive", { 0: "$ZC" })}
              </label>
              <div className="card-input-polygon">
                <div className="input-group">
                  <span className="input-group-text">
                    <Image
                      src="/images/icons/ico-ZC.png"
                      alt="ico-zc"
                      width="32"
                    />
                    <span>ZC</span>
                  </span>
                  <input
                    type="text"
                    placeholder="0"
                    className="form-control text-end"
                    value={amountToken}
                    onChange={handleInputTokenChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Connect Wallet */}
      <div className="mb-4">
        {!account ? (
          <BtnConnect trackEvt={Consts.GA_EVENTS.btn_login_eth} />
        ) : (
          <button
            type="button"
            className="btn btn-polygon-primary"
            onClick={handleBuyClick}
          >
            <span>
              {!is_processing
                ? `Buy & Stake ${account.slice(0, 6)}...${account.slice(
                    account.length - 4,
                    account.length
                  )}`
                : `${step}`}
            </span>
          </button>
        )}
      </div>
    </>
  );
};
export default BuyOnETH;
