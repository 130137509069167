import queryString from "query-string";
import { web3 as web3Utis } from "../consts";
import { presaleConstants, web3Constants } from "../constants";

import { alertActions } from "./alert.actions";
import ABI_PRESALE from "../_contracts/zc/ZCPreSale.json";
import ABI_PRESALE_BSC from "../_contracts/zc/ZCPreSaleBsc.json";
import ABI_ERC20 from "../_contracts/abi-erc20.json";

// import { web3Connect } from "./web3.actions";
import { formatUriSecure, getNumberEther, getNumberUSDT } from "../utils/lib";
import { ceil } from "lodash";
import { ethers } from "ethers";
import { initBalanceETH, initUSDTContract } from "./web3.actions";

export const PRESALE_SMC = process.env.REACT_APP_TOKEN_PRESALE_ETH;
export const PRESALE_SMC_BSC = process.env.REACT_APP_TOKEN_PRESALE_BSC;
export const TOKEN_USDT_BSC = process.env.REACT_APP_TOKEN_USDT_BSC;

export const loadFunding = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: presaleConstants.FETCH_PRESALE_REQUEST,
    });

    // https://api-staging.lunarush.org/v1/market_place_api/api/boxes/market
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/api/pre-sale/fund_raised`;

    const response = await fetch(url);
    const responseBody = await response.json();
    const { data } = responseBody;
    console.log(data);
    dispatch({
      type: presaleConstants.FETCH_PRESALE_SUCCESS,
      data: data || {},
    });
  } catch (error) {
    dispatch({
      type: presaleConstants.FETCH_PRESALE_ERROR,
      message: error,
    });
  }
};

export const loadPresaleBalance = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: presaleConstants.FETCH_PRESALE_BALANCE_REQUEST,
    });

    const state = getState();
    const { web3, account } = state.web3;
    const querySearch = {
      purchaser_address: account || "",
    };
    const linkQuery = queryString.stringify(querySearch);
    // https://api-staging.lunarush.org/v1/market_place_api/api/boxes/market
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/api/pre-sale/balance?${linkQuery}`;

    const response = await fetch(url);
    const responseBody = await response.json();
    const { data } = responseBody;
    console.log(data);
    dispatch({
      type: presaleConstants.FETCH_PRESALE_BALANCE_SUCCESS,
      data: data || {},
    });
  } catch (error) {
    dispatch({
      type: presaleConstants.FETCH_PRESALE_BALANCE_ERROR,
      message: error,
    });
  }
};

export const loadPreSaleInformation = () => async (dispatch, getState) => {
  const state = getState();

  const { web3_eth, web3_bsc } = state.web3;
  console.log("PRESALE_SMC", PRESALE_SMC);

  if (web3_eth) {
    const contractPreSale = new web3_eth.eth.Contract(ABI_PRESALE, PRESALE_SMC);
    try {
      const rounds = await contractPreSale.methods.getAllRoundInfo().call();
      console.log({ rounds });

      dispatch({
        type: presaleConstants.FETCH_PRESALE_ROUND_INFO_SUCCESS,
        contractPreSale,
        rounds,
      });
    } catch (error) {
      console.log("---error loadPreSaleInformation: rounds", error);
      return error;
    }

    try {
      const roundIdx = await contractPreSale.methods.getCurrentRound().call();
      const nZCtoETH = 10000;
      let rateZCtoETH = await contractPreSale.methods
        .zcToEth(web3Utis.utils.toWei(nZCtoETH.toString(), "ether"))
        .call();
      rateZCtoETH = getNumberEther(rateZCtoETH, true, 4);
      dispatch({
        type: presaleConstants.FETCH_PRESALE_ROUND_INFO_SUCCESS,
        roundIdx,
        rateZCtoETH,
      });
    } catch (error) {
      console.log("---error loadPreSaleInformation: roundIdx", error);
      return error;
    }
  }

  if (web3_bsc) {
    const contractPreSaleBSC = new web3_bsc.eth.Contract(
      ABI_PRESALE_BSC,
      PRESALE_SMC_BSC
    );
    try {
      const nZCtoBNB = 10000;
      let rateZCtoBNB = await contractPreSaleBSC.methods
        .zcToBnb(web3Utis.utils.toWei(nZCtoBNB.toString(), "ether"))
        .call();
      rateZCtoBNB = getNumberEther(rateZCtoBNB, true, 4);

      console.log({ rateZCtoBNB });

      dispatch({
        type: presaleConstants.W3_PRESALE_BSC_SUCCESS,
        contractPreSaleBSC,
        rateZCtoBNB,
      });
    } catch (error) {
      console.log("---error loadPreSaleInformation", error);

      dispatch({
        type: presaleConstants.W3_PRESALE_BSC_ERROR,
        message: error,
      });
      return error;
    }
  }
};

export const buyAndStakeInEth = (amount) => async (dispatch, getState) => {
  const state = getState();
  const { web3, account } = state.web3;
  const PRESALE_SMC = process.env.REACT_APP_TOKEN_PRESALE_ETH;
  const contractPreSale = new web3.eth.Contract(ABI_PRESALE, PRESALE_SMC);

  if (web3 && contractPreSale && amount) {
    console.log("START BUY AND STAKE IN ETH");
    let amount_zc = web3Utis.utils.toWei(amount.toString(), "ether");
    let amount_eth = await contractPreSale.methods.zcToEth(amount_zc).call();
    console.log({ amount_zc, amount, amount_eth });
    await contractPreSale.methods
      .buyAndStakeInEth(amount_zc)
      .send({ from: account, value: amount_eth })
      .then((transaction) => {
        console.log(transaction);
        // dispatch({
        //   type: stakingGemConstants.MODAL_STAKING_GEM_SUCCESS,
        //   transaction,
        // });

        // setTimeout(() => {
        //   dispatch(loadAccountGemStaked());
        //   dispatch(loadInfoStakingGem());
        //   dispatch(actions.instantiateLUSContracts());
        // }, 1000);

        // setTimeout(() => {
        //   dispatch(loadFetchStakingGemForAccount());
        // }, 2000);

        return transaction;
      })
      .catch((e) => {
        console.log("------error buyAndStakeInEth", e);
        throw e;
      });
  }
};

export const transferEther = async (dispatch, toAddress, amount) => {
  if (window.ethereum) {
    console.log("MetaMask detected!");
    console.log({ toAddress, amount });
    const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const transaction = await signer.sendTransaction({
      to: toAddress,
      value: ethers.utils.parseEther(amount.toString()), // Amount in Ether
      // value: amount, // Amount in Ether
    });
    console.log("Transaction sent:", transaction);
    dispatch({
      type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
      step: "Waiting Transaction....",
      is_processing: true,
    });
    await transaction.wait();
    return transaction;
  } else {
    console.error("MetaMask not detected. Please install it.");
  }
};

export const transferUsdt = async (dispatch, toAddress, amount) => {
  if (window.ethereum) {
    console.log("MetaMask detected!");
    console.log({ toAddress, amount });
    const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    // Connect to your ERC-20 token contract
    const tokenContract = new ethers.Contract(
      TOKEN_USDT_BSC,
      ABI_ERC20,
      signer
    );

    // Convert the amount to BigNumber
    const amountBN = ethers.utils.parseUnits(amount.toString(), 18); // Assuming 18 decimals for ERC-20 tokens

    // Call the transfer function on the token contract
    const tx = await tokenContract.transfer(toAddress, amountBN);

    // Wait for the transaction to be mined
    dispatch({
      type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
      step: "Waiting Transaction....",
      is_processing: true,
    });
    await tx.wait();
    console.log("Transaction sent:", tx);
    return tx;
  } else {
    console.error("MetaMask not detected. Please install it.");
  }
};

export const buyInBSCwBNB =
  (amount, amount_bnb) => async (dispatch, getState) => {
    const state = getState();
    const { web3, account } = state.web3;

    if (web3 && account && amount) {
      console.log("START BUY IN BSC with BNB");

      const contractPreSaleBSC = new web3.eth.Contract(
        ABI_PRESALE_BSC,
        PRESALE_SMC_BSC
      );
      let amount_zc = web3Utis.utils.toWei(amount.toString(), "ether");
      let amount_eth = await contractPreSaleBSC.methods
        .zcToBnb(amount_zc)
        .call();
      // const fundSpenderWallet = BSC_FUND_SPENDER;
      const fundSpenderWallet = await contractPreSaleBSC.methods
        .fundSpender()
        .call();
      console.log({ amount_zc, amount, amount_eth, fundSpenderWallet });
      console.log("Call transfer bnb token");
      await transferEther(dispatch, fundSpenderWallet, amount_bnb);
    }
  };

export const buyInBSCwUSDT =
  (amount, amountUsdt) => async (dispatch, getState) => {
    const state = getState();
    const { web3, account } = state.web3;

    if (web3 && account && amount) {
      console.log("START BUY IN BSC with USDT");

      const contractPreSaleBSC = new web3.eth.Contract(
        ABI_PRESALE_BSC,
        PRESALE_SMC_BSC
      );
      let amount_zc = web3Utis.utils.toWei(amount.toString(), "ether");
      // let amount_eth = await contractPreSaleBSC.methods.zcToUsdt(amount_zc).call();
      // let amount_usdt = web3Utis.utils.toWei(amountUsdt.toString(), "Mwei");

      // const fundSpenderWallet = BSC_FUND_SPENDER;
      const fundSpenderWallet = await contractPreSaleBSC.methods
        .fundSpender()
        .call();

      await transferUsdt(dispatch, fundSpenderWallet, amountUsdt);
    }
  };

export const approveUsdt = async (
  contractUsdt,
  amount_usdt,
  account,
  spender
) => {
  console.log({ contractUsdt, amount_usdt, account, spender });
  let amount_allowace = await contractUsdt.methods
    .allowance(account, spender)
    .call();

  amount_allowace = getNumberUSDT(amount_allowace, true, 4);
  amount_allowace = parseInt(amount_allowace);
  console.log({ amount_allowace });
  if (amount_allowace && amount_allowace >= parseInt(ceil(amount_usdt))) {
    console.log("amount_allowace >= amount", amount_allowace, parseInt(ceil(amount_usdt)));
    return true;
  }
  console.log("START approveUsdt", contractUsdt, amount_usdt);
  // let amount_approved = ceil(value_usdt);
  let amount_approved = web3Utis.utils.toWei(ceil(amount_usdt).toString(), "Mwei");
  console.log("amount_approved", amount_approved);
  await contractUsdt.methods
    .approve(spender, amount_approved)
    .send({ from: account })
    .then((transaction) => {
      console.log(transaction);
      return true;
    })
    .catch((e) => {
      console.log("------error approveUsdt", e);
      throw e;
    });

  return true;
};

export const buyAndStakeInEthwUSDT =
  (amount, amountUsdt) => async (dispatch, getState) => {
    const state = getState();
    const { web3, account } = state.web3;
    const { contractUSDT } = state.w3Usdt;

    const PRESALE_SMC = process.env.REACT_APP_TOKEN_PRESALE_ETH;
    const contractPreSale = new web3.eth.Contract(ABI_PRESALE, PRESALE_SMC);

    if (web3 && contractPreSale && amount) {
      console.log("START BUY AND STAKE IN ETHwUSDT");
      let amount_zc = web3Utis.utils.toWei(amount.toString(), "ether");
      // let amount_usdt = await contractPreSale.methods.zcToUsdt(amount_zc).call();
      let amount_usdt = web3Utis.utils.toWei(amountUsdt.toString(), "Mwei");
      console.log({ amount_zc, amount, amount_usdt });

      dispatch({
        type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
        step: "Approving USDT....",
        is_processing: true,
      });
      // 1. Approved USDT
      const SMC_PRESALE_ADDRESS = process.env.REACT_APP_TOKEN_PRESALE_ETH;
      console.log("SMC_PRESALE_ADDRESS", SMC_PRESALE_ADDRESS);
      let is_approved = await approveUsdt(
        contractUSDT,
        amountUsdt,
        account,
        SMC_PRESALE_ADDRESS
      );
      if (!is_approved) {
        dispatch(alertActions.error("1. Must approve USDT"));
        return;
      }

      dispatch({
        type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
        step: "Process Transaction....",
        is_processing: true,
      });
      // 2. Buy and Stake
      console.log("START CALL SMC: buyAndStakeInUsdt");
      await contractPreSale.methods
        .buyAndStakeInUsdt(amount_zc)
        .send({ from: account })
        .then((transaction) => {
          console.log(transaction);
          return transaction;
        })
        .catch((e) => {
          console.log("------error buyAndStakeInEth", e);
          
          throw e;
        });
    }
  };

export const stakeForApy = (amount) => async (dispatch, getState) => {
  console.log("stakeForApy", amount);
  const state = getState();
  const { web3, account } = state.web3;
  if (window.ethereum && account) {
    const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();

    const nonce = Math.floor(Math.random() * 1000000) + 1;

    // Build sign message
    const messgae_sign = `Claim and Stake: ${amount} ZC with nounce ${nonce}`;
    const msg = `0x${Buffer.from(messgae_sign, "utf8").toString("hex")}`;
    const sign_hash = await window.ethereum.request({
      method: "personal_sign",
      params: [msg, account],
    });
    console.log(sign_hash);

    const querySearch = {
      purchaser_address: account,
      zc_amount: ethers.utils.parseUnits(amount.toString(), 18),
    };
    const linkQuery = queryString.stringify(querySearch);
    // get Signature for permit amount
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/api/pre-sale/signature?${linkQuery}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: messgae_sign,
        sig: sign_hash,
      }),
    });
    const responseBody = await response.json();
    const { data } = responseBody;
    if (!data) {
      dispatch(alertActions.error("Signature error"));
      return;
    }

    const { spender, deadline, value, v, r, s } = data.signature;

    await dispatch({
      type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
      step: "Process Transaction...",
      is_processing: true,
    });
    // Connect to your ERC-20 token contract
    const tokenContract = new ethers.Contract(PRESALE_SMC, ABI_PRESALE, signer);
    // Call the transfer function on the token contract
    const tx = await tokenContract.permitAndStake(
      spender,
      value,
      deadline,
      v,
      r,
      s
    );
    // Wait for the transaction to be mined
    await dispatch({
      type: web3Constants.UPDATE_STEP_BUY_IN_ETH,
      step: "Waiting Transaction...",
      is_processing: true,
    });
    await tx.wait();
    console.log("Transaction sent:", tx);
    return tx;
  }
};
