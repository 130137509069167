import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import BtnConnect from "../components/account/BtnConnect";

import EarnApy from "../components/homepage/EarnApy";
// import ModalStake from "../components/ModalStake";
import StakeForApy from "../components/staking/StakeForApy";

// import TestWeb3 from "../components/dummy/TestWeb3";
import * as actions from "../actions";
import { routerHeaderTitle } from "../_helpers/param";

import {
  numberWithCommas,
  getNumberEther,
  showNumberOrDash,
  showAliasWalletAddress,
  getChainConnect,
  roundNumberWithDecimal,
  isMobileOrTablet,
} from "../utils/lib";
import { Image } from "react-bootstrap";
import i18next from "i18next";
import { Trans } from "react-i18next";

import * as Consts from "../consts";

const PageStaking = () => {
  const dispatch = useDispatch();

  const { config, stakeInfo, stakeLeaderboard } = useSelector(
    (state) => state.w3Stake,
    shallowEqual
  );

  const { presaleBalance } = useSelector(
    (state) => state.presaleReducer,
    shallowEqual
  );
  const { account } = useSelector((state) => state.web3, shallowEqual);

  const [firstVisitPage, setFistVisitPage] = useState(Date.now());
  const [nowMs, setNowMs] = useState(Date.now());
  useEffect(() => {
    const timer = setTimeout(() => {
      const now_ms = Date.now();
      setNowMs(now_ms);

      const duration = Math.floor((now_ms - firstVisitPage) / 1000);
      const evtArgs = { screen_name: "staking" };
      switch (duration) {
        case 10:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_10, evtArgs);
          break;

        case 20:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_20, evtArgs);
          break;

        case 30:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_30, evtArgs);
          break;

        case 45:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_45, evtArgs);
          break;

        case 60:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_60, evtArgs);
          break;

        case 90:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_90, evtArgs);
          break;

        case 120:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_120, evtArgs);
          break;

        case 240:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_240, evtArgs);
          break;

        case 480:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_480, evtArgs);
          break;

        case 720:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_720, evtArgs);
          break;

        case 2048:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_2048, evtArgs);
          break;

        default:
          break;
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  const [
    enabledStaking,
    enabledWithdrawZc,
    defaultApy,
    numberOfRounds,
    secondsPerRound,
    unstakeTimelock,
    presaleStartAt,
    currentApy,
  ] = config;

  useEffect(() => {
    let chain_connect = getChainConnect();
    if (chain_connect !== actions.ETH_NETWORK_NAME) {
      console.log("Switch to ETH");
      dispatch(actions.switchChain(actions.ETH_NETWORK_NAME));
    }

    if (!stakeLeaderboard) {
      dispatch(actions.loadStakeLeaderboard());
    }
    if (!presaleBalance) {
      dispatch(actions.loadPresaleBalance());
    }
    console.log(stakeLeaderboard);
  }, [stakeLeaderboard]);

  useEffect(() => {
    document.title = routerHeaderTitle["staking"];
    // Change meta tag on component mount
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        routerHeaderTitle["staking_description"]
      );
    }

    // Optionally, revert the change on component unmount
    return () => {
      if (metaDescription) {
        metaDescription.setAttribute(
          "content",
          routerHeaderTitle["staking_description"]
        );
      }
    };
  }, []);

  const totalOffchainBalance = () => {
    let balance = getNumberEther(_.get(presaleBalance, "balance", 0), true, 2);
    let pending = getNumberEther(
      _.get(presaleBalance, "balance_pending", 0),
      true,
      2
    );
    let total = balance + pending;
    total = roundNumberWithDecimal(total, 2);
    return showNumberOrDash(total);
  };

  return (
    <>
      <div className="stalking-page">
        {/* My $ZC Staking */}
        <section className="stalking-section position-relative">
          {/* Decoration */}
          <div className="card-polygon-decoration top"></div>
          <div className="card-polygon-decoration left"></div>
          <div className="container">
            <h1 className="display-3 text-center text-uppercase">
              {i18next.t("my_zc_staking", "My $ZC Staking")}
            </h1>
            <div className="row">
              <div className="col-lg-8 mx-auto">
                {/*BSC*/}
                {account ? (
                  <div className="card card-polygon-secondary text-primary mb-3">
                    <div className="card-body text-center">
                      <b>ETH:</b>
                      <span className="px-1">{`(${account.slice(
                        0,
                        8
                      )}...${account.slice(
                        account.length - 6,
                        account.length
                      )})`}</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* List */}
                <div className="card card-polygon-list mb-4">
                  <ul className="list-group text-center text-md-start">
                    {/*Estimated rewards*/}
                    <li className="list-group-item active">
                      <p>
                        {i18next.t("estimated_rewards", "Estimated rewards")}
                      </p>
                      <b>{currentApy}%</b>
                      <small className="ms-2">APR</small>
                    </li>
                    {/*Available In Wallet*/}
                    <li className="d-none list-group-item">
                      <p>Available In Wallet</p>
                      <b>0</b>
                      <small className="ms-2">ZC</small>
                    </li>
                    {/*Total Staked*/}
                    <li className="list-group-item">
                      <div className="row row-cols-1 row-cols-md-2">
                        <div className="col mb-3 mb-md-0">
                          <p>{i18next.t("total_staked", "Total Staked")}</p>
                          <b>
                            {showNumberOrDash(
                              _.get(stakeInfo, "stakeBalance", 0),
                              true
                            )}
                          </b>
                          <span className="ms-2">ZC</span>
                        </div>
                        <div className="col text-end d-md-flex align-items-center justify-content-end">
                          <button
                            type="button"
                            className="btn btn-polygon-primary disabled"
                          >
                            {i18next.t("unstake", "Unstake")}
                          </button>
                        </div>
                      </div>
                    </li>
                    {/*Total Claimable*/}
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-12 col-md-4 mb-3 mb-lg-0">
                          <p>
                            {i18next.t("total_claimable", "Total Claimable")}
                          </p>
                          <b>{totalOffchainBalance()}</b>
                          <span className="ms-2">ZC</span>
                        </div>
                        <div className="col-12 col-md-8 text-center text-md-end d-md-flex align-items-center justify-content-end">
                          <StakeForApy currentApy={currentApy} />
                          <button
                            type="button"
                            className="btn btn-polygon-secondary btn-md disabled"
                          >
                            {i18next.t("claim", "Claim")}
                          </button>
                        </div>
                      </div>
                    </li>
                    {/*Claimable Rewards*/}
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-12 col-md-4 mb-3 mb-lg-0">
                          <p>
                            {i18next.t(
                              "claimable_rewards",
                              "Claimable Rewards"
                            )}
                          </p>
                          <b className="text-primary">
                            {showNumberOrDash(
                              _.get(stakeInfo, "estimatedYield", 0),
                              true
                            )}
                          </b>
                          <span className="ms-2">ZC</span>
                        </div>
                        <div className="col-12 col-md-8 text-center text-md-end d-md-flex align-items-center justify-content-end">
                          <button
                            type="button"
                            className="btn btn-polygon-third btn-md me-md-2 mb-md-0 mb-2 disabled"
                          >
                            {i18next.t("restake", "Restake")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-polygon-secondary btn-md disabled"
                          >
                            {i18next.t("claim", "Claim")}
                          </button>
                        </div>
                      </div>
                    </li>
                    {/*Pedding Rewards*/}
                    <li className="d-none list-group-item py-3">
                      <div className="row row-cols-1 row-cols-md-2">
                        <div className="col mb-2 mb-lg-0 py-3">
                          <p>
                            {i18next.t("pending_rewards", "Pending Rewards")}
                          </p>
                          <b className="text-primary">230.29</b>
                          <span className="ms-2">ZC</span>
                        </div>
                        <div className="col d-flex align-items-center justify-content-end">
                          <div className="row col-12 mx-auto">
                            <div className="col-12 mb-lg-1 mb-2 text-center">
                              <small className="text-uppercase fw-bold">
                                {i18next.t(
                                  "receive_rewards_after",
                                  "Receive rewards after:"
                                )}
                              </small>
                            </div>
                            <div className="col-12 text-center">
                              <div className="row">
                                {/* Days */}
                                <div className="col-3">
                                  <div className="card card-squares card-squares-sm">
                                    <div className="card-body">
                                      <h1 className="mb-0 text-body">10</h1>
                                      <small className="text-uppercase text-body">
                                        {i18next.t("days", "days")}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                {/* Hours */}
                                <div className="col-3">
                                  <div className="card card-squares card-squares-sm">
                                    <div className="card-body">
                                      <h1 className="mb-0 text-body">20</h1>
                                      <small className="text-uppercase text-body">
                                        {i18next.t("hours", "hours")}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                {/* Minutes */}
                                <div className="col-3">
                                  <div className="card card-squares card-squares-sm">
                                    <div className="card-body">
                                      <h1 className="mb-0 text-body">30</h1>
                                      <small className="text-uppercase text-body">
                                        {i18next.t("minutes", "minutes")}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                {/* Seconds */}
                                <div className="col-3">
                                  <div className="card card-squares card-squares-sm">
                                    <div className="card-body">
                                      <h1 className="mb-0 text-body">40</h1>
                                      <small className="text-uppercase text-body">
                                        {i18next.t("seconds", "seconds")}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {!account ? (
                  <BtnConnect
                    styleClass={"btn btn-polygon-primary mb-4 d-block mx-auto"}
                    urlDeepLink={Consts.DEEP_LINKS.META_MASK_STAKING}
                    trackEvt={Consts.GA_EVENTS.btn_login_sp}
                  />
                ) : (
                  ""
                )}
                {/* <button
                  className="btn btn-polygon-primary mb-4 d-block mx-auto"
                  type="submit"
                >
                  Connect Wallet
                </button> */}
                <div id="faq_scroll">
                  <a
                    type="button"
                    className="btn btn-link d-block mx-auto"
                    href="/#FAQ"
                  >
                    {i18next.t("faq")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Leaderboard Staking */}
        <section className="leaderboard-section position-relative">
          <div className="container">
            <h1 className="display-3 text-center text-uppercase">
              {i18next.t("lb_staking_title")}
            </h1>
            <div className="row row-cols-1 row-cols-xl-2">
              <div className="col">
                <div className="card card-polygon-list card-polygon-table">
                  <ul className="list-group">
                    {_.get(stakeLeaderboard, "lbs", []).map((item) => (
                      <li
                        className="list-group-item"
                        key={item["wallet_address"]}
                      >
                        <div className="row">
                          <div className="col-md-1 text-md-start text-center mb-md-0 mb-3">
                            <b>
                              {item["rank"] < 10
                                ? "0" + item["rank"]
                                : item["rank"]}
                            </b>
                          </div>
                          <div className="col-md-9 text-md-start text-center text-truncate mb-md-0 mb-3">
                            <span>{item["wallet_address"]}</span>
                          </div>
                          <div className="col-md-2 text-md-end text-center text-truncate">
                            <span className="list-group-number">
                              {numberWithCommas(
                                getNumberEther(item["stake_balance"], true, 0)
                              )}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                    {_.get(stakeLeaderboard, "me.rank") ? (
                      <li className="list-group-item active">
                        <div className="row">
                          <div className="col-md-1 text-md-start text-center mb-md-0 mb-3">
                            <b>{_.get(stakeLeaderboard, "me.rank")}</b>
                          </div>
                          <div className="col-md-9 text-md-start text-center text-truncate mb-md-0 mb-3">
                            <span>Your Rank</span>
                          </div>
                          <div className="col-md-2 text-md-end text-center text-truncate">
                            <span className="list-group-number">
                              {numberWithCommas(
                                getNumberEther(
                                  _.get(stakeLeaderboard, "me.stake_balance"),
                                  true,
                                  0
                                )
                              )}
                            </span>
                          </div>
                        </div>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
              <div className="col ps-3">
                <div className="text-center">
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster="/video/box.png"
                  >
                    {!isMobileOrTablet() && (
                      <source src="/video/video_box.webm" type="video/webm" />
                    )}
                  </video>
                </div>
                <div className="card card-transparent">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <label
                          title=""
                          htmlFor="check_label"
                          className="form-check-label fw-normal"
                        >
                          {i18next.t("lb_staking_des_1")}
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: i18next.t("lb_staking_des_2"),
                          }}
                        ></div>
                      </div>
                    </li>
                    <li className="list-group-item mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <label
                          title=""
                          htmlFor="check_label"
                          className="form-check-label fw-normal"
                        >
                          {i18next.t("lb_staking_des_3")}
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item d-none">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <label
                          title=""
                          htmlFor="check_label"
                          className="form-check-label fw-normal"
                        >
                          Finally, the special feature of Zap Clash is the "Zap"
                          mechanism. When two players face each other, the
                          winner will receive a trophy and conversely, the loser
                          will lose a trophy. At any point in the match, both
                          players can Zap to double their bet.
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Earn Apy During Presale */}
        <EarnApy />
      </div>
      {/* <ModalStake /> */}
    </>
  );
};

export default PageStaking;
