

const CircleContainer = () => {

    return (
        <>

            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>
            <div className="circle-container">
                <div className="circle"></div>
            </div>

        </>
    )
}


export default CircleContainer;
