import Web3 from "web3";

export const version = "v1.0.4";
export const web3 = new Web3();

export const DEEP_LINKS = {
  META_MASK: process.env.REACT_APP_DEEP_LINK_METAMASK,
  META_MASK_STAKING: process.env.REACT_APP_DEEP_LINK_METAMASK_STAKING,
};

export const GA_EVENTS = {
  btn_open_in_metamask: "btn_open_in_metamask",
  btn_login_bsc: "btn_login_bsc",
  btn_login_eth: "btn_login_eth",
  btn_buy: "btn_buy",
  btn_twitter_leftside: "btn_twitter_leftside",
  btn_tele_leftside: "btn_tele_leftside",
  btn_menu_presale: "btn_menu_presale",
  btn_menu_gameplay: "btn_menu_gameplay",
  btn_menu_roadmap: "btn_menu_roadmap",
  btn_menu_tokenomic: "btn_menu_tokenomic",
  btn_menu_team: "btn_menu_team",
  btn_change_language: "btn_change_language",
  btn_audit: "btn_audit",
  btn_stacking: "btn_stacking",
  btn_whitepaper: "btn_whitepaper",
  btn_linkedin_ceo: "btn_linkedin_ceo",
  btn_youtube: "btn_youtube",
  btn_login_sp: "btn_login_sp",
  btn_stake_sp: "btn_stake_sp",
  duration_10: "duration_10",
  duration_20: "duration_20",
  duration_30: "duration_30",
  duration_45: "duration_45",
  duration_60: "duration_60",
  duration_90: "duration_90",
  duration_120: "duration_120",
  duration_240: "duration_240",
  duration_480: "duration_480",
  duration_720: "duration_720",
  duration_2048: "duration_2048",
  buy_bsc_usdt: "buy_bsc_usdt",
  buy_bsc_bnb: "buy_bsc_bnb",
  buy_eth_usdt: "buy_eth_usdt",
  buy_eth_eth: "buy_eth_eth",
};

export const trackingEventGA = (
  event,
  evtArgs = { screen_name: "homepage" }
) => {
  if (window.gtag && event) {
    console.log("trackingEventGA", event);
    window.gtag("event", event, evtArgs);
  }
};

export const handlerClickURL = (
  event,
  url,
  isOpen = false,
  evtArgs = { screen_name: "homepage" }
) => {
  trackingEventGA(event, evtArgs);
  if (isOpen) {
    window.open(url);
  } else {
    window.location.href = url;
  }
};
