import { presaleConstants } from "../constants";

const initialState = {
  requesting: false,
  success: false,
  message: null,
  data: {},
  presaleBalance: null,
};

export function presaleReducer(state = initialState, payload) {
  // console.log({payload});
  switch (payload.type) {
    case presaleConstants.FETCH_PRESALE_REQUEST:
      return {
        ...state,
        data: {},
        requesting: true,
      };
    case presaleConstants.FETCH_PRESALE_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        data: payload.data,
      };
    case presaleConstants.FETCH_PRESALE_BALANCE_SUCCESS:
      return {
        ...state,
        presaleBalance: payload.data,
      };
    case presaleConstants.FETCH_PRESALE_ERROR:
      return {
        ...state,
        requesting: false,
        message: payload.message,
      };

    default:
      return state;
  }
}
