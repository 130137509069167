import { web3Constants } from "../constants";

import { getChainConnect } from "../utils/lib";

const initialState = {
  web3: null,
  account: null,
  web3_eth: null,
  balance_eth: 0,
  web3_bsc: null,
  balance_bnb: 0,
  smc_stake: null,
  smc_presale: null,
  chain_connect: getChainConnect(),
  language: null,
};

export function web3(state = initialState, action) {
  switch (action.type) {
    case web3Constants.WEB3_CONNECT:
      return {
        ...state,
        account: action.account,
        web3: action.web3,
      };
    case web3Constants.WEB3_DISCONNECT:
      return {
        ...state,
        account: null,
        web3: null,
      };
    case web3Constants.WEB3_INIT:
      return {
        ...state,
        web3_eth: action.web3_eth,
        web3_bsc: action.web3_bsc,
        balance_eth: action.balance_eth,
        balance_bnb: action.balance_bnb,
        smc_stake: action.smc_stake,
        smc_presale: action.smc_presale,
      };
    case web3Constants.WEB3_INIT_BALANCE:
      return {
        ...state,
        balance_eth: action.balance_eth,
        balance_bnb: action.balance_bnb,
      };
    case web3Constants.WEB3_SWITCH_CHAIN:
      return {
        ...state,
        chain_connect: action.chain_connect,
      };
    case web3Constants.SWITCH_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
}
