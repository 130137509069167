import { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import * as actions from "../../actions";
import i18next from "i18next";

import { isMobileOrTablet } from "../../utils/lib";
import * as Consts from "../../consts";

const BtnConnect = ({
  styleClass = "btn btn-polygon-primary",
  urlDeepLink = Consts.DEEP_LINKS.META_MASK,
  trackEvt = "btn_login_other",
}) => {
  
  const [isConnecting, setIsConnecting] = useState(false);

  const { account } = useSelector((state) => state.web3, shallowEqual);

  const dispatch = useDispatch();

  const connectWalletHandler = async () => {
    if (
      isMobileOrTablet() &&
      !(window.ethereum && window.ethereum.isMetaMask)
    ) {
      Consts.trackingEventGA(Consts.GA_EVENTS.btn_open_in_metamask);
      window.location.href = urlDeepLink;
      return;
    }

    Consts.trackingEventGA(trackEvt);
    if (!isConnecting) {
      setIsConnecting(true);
      await dispatch(actions.web3Connect(true))
        .then(() => {
          setIsConnecting(false);
        })
        .catch((e) => {
          setIsConnecting(false);
        });
    }
  };

  const disConnectWallet = async () => {
    await dispatch(actions.web3Disconnect());
  };

  return (
    <div className="box-account-wallet">
      {account ? (
        <div>
          <button
            className="btn btn-primary"
            type="submit"
            onClick={disConnectWallet}
          >
            {`${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length
            )}`}
          </button>
          {/* <img
            onClick={disConnectWallet}
            className="btn btn-primary btn-logout"
            src={IcLogout}
            alt="Logout"
            title="Logout"
          /> */}
        </div>
      ) : (
        <button
          onClick={connectWalletHandler}
          className={styleClass}
          type="submit"
        >
          {isMobileOrTablet() &&
          !(window.ethereum && window.ethereum.isMetaMask)
            ? i18next.t("connect_wallet_metamask")
            : `${isConnecting ? "Loading..." : i18next.t("connect_wallet")}`}
        </button>
      )}
    </div>
  );
};

export default BtnConnect;
