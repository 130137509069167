import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { routerHeaderTitle } from "../_helpers/param";

import Welcome from "../components/homepage/Welcome";
import PreSaleNStake from "../components/presale_stake/PreSale_Stake";
import TokenZC from "../components/homepage/TokenZC";
import Tokennomic from "../components/homepage/Tokennomic";
import GamePlay from "../components/homepage/GamePlay";
import EarnApy from "../components/homepage/EarnApy";
import BurningMechanism from "../components/homepage/BurningMechanism";
import RoadMap from "../components/homepage/Roadmap";
import Team from "../components/homepage/Team";
import FaQ from "../components/homepage/FaQ";

import * as actions from "../actions";
import * as Consts from "../consts";

const HomePage = () => {
  const dispatch = useDispatch();

  const { rounds, roundIdx } = useSelector(
    (state) => state.w3PreSale,
    shallowEqual
  );

  const [firstVisitPage, setFistVisitPage] = useState(Date.now());
  const [nowMs, setNowMs] = useState(Date.now());
  useEffect(() => {
    const timer = setTimeout(() => {
      const now_ms = Date.now();
      setNowMs(now_ms);

      const duration = Math.floor((now_ms - firstVisitPage) / 1000);
      const evtArgs = { screen_name: "homepage" };
      switch (duration) {
        case 10:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_10, evtArgs);
          break;

        case 20:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_20, evtArgs);
          break;

        case 30:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_30, evtArgs);
          break;

        case 45:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_45, evtArgs);
          break;

        case 60:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_60, evtArgs);
          break;

        case 90:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_90, evtArgs);
          break;

        case 120:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_120, evtArgs);
          break;

        case 240:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_240, evtArgs);
          break;

        case 480:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_480, evtArgs);
          break;

        case 720:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_720, evtArgs);
          break;

        case 2048:
          Consts.trackingEventGA(Consts.GA_EVENTS.duration_2048, evtArgs);
          break;

        default:
          break;
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    document.title = routerHeaderTitle["homepage"];
    fetchFunding();
    if (!roundIdx) {
      dispatch(actions.loadPreSaleInformation());
    }
  }, []);

  const currentRound = () => {
    if (!roundIdx) return [null, null, null, null];
    return rounds[Number.parseInt(roundIdx) - 1];
  };

  const nextRound = () => {
    if (!rounds) return [null, null, null, null];
    if (!roundIdx) return rounds[0];
    if (Number.parseInt(roundIdx) === rounds.length)
      return [null, null, null, null];
    return rounds[Number.parseInt(roundIdx)];
  };

  const handleCountdownZero = () => {
    if (Number.parseInt(roundIdx) === rounds.length) {
      return;
    }

    setTimeout(() => {
      dispatch(actions.loadPreSaleInformation());
    }, 10);
  };

  async function fetchFunding() {
    await dispatch(actions.loadPreSaleInformation());
    await dispatch(actions.loadFunding());
  }

  return (
    <section>
      <Welcome
        // currentRound={currentRound()}
        nextRound={nextRound()}
        handleCountdownZero={handleCountdownZero}
      />
      <PreSaleNStake
        currentRound={currentRound()}
        nextRound={nextRound()}
        handleCountdownZero={handleCountdownZero}
      />
      <GamePlay />
      <EarnApy />
      <TokenZC />
      <Tokennomic />
      <BurningMechanism />
      <RoadMap />
      <Team />
      <FaQ />
    </section>
  );
};

export default HomePage;
