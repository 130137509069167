import { presaleConstants } from "../constants";

const initialState = {
  rounds: null,
  roundIdx: null,
  contractPreSale: null,
  rateZCtoETH: null,
  rateZCtoBNB: null,
};

export function w3PreSale(state = initialState, action) {
  switch (action.type) {
    case presaleConstants.FETCH_PRESALE_ROUND_INFO_SUCCESS:
      return {
        ...state,
        ...action,
      };

    case presaleConstants.FETCH_PRESALE_ROUND_INFO_ERROR:
      return {
        ...state,
      };

    case presaleConstants.W3_PRESALE_BSC_SUCCESS:
      return {
        ...state,
        rateZCtoBNB: action.rateZCtoBNB,
      };

    default:
      return state;
  }
}
