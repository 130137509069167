import React, { useState, useEffect } from "react";

import { Outlet } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

// import ReLoader from '../components/partials/ReLoader';
import IcToken from "../assets/images/coin.png";

import Header from "../components/partials/Header";
import Footer from "../components/partials/Footer";

import i18n from "../translation";

const MainLayout = () => {
  const [spinner, setSpinner] = useState(true);
  const { language } = useSelector((state) => state.web3, shallowEqual);

  useEffect(() => {
    setTimeout(() => setSpinner(false), 1000);
  }, []);

  return spinner ? (
    <div className="divLoader">
      <div className="spinner">
        <div className="spinner-item"></div>
        <div className="spinner-item"></div>
        <div className="spinner-item"></div>
        <img src={IcToken} alt="Coin ZC" />
      </div>
    </div>
  ) : (
    <div
      className="wrapper-zc"
      lang={language || i18n.language.split("-")[0].toLowerCase()}
    >
      <>
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </>
    </div>
  );
};

export default MainLayout;
