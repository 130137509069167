import _ from "lodash";
import React, { useEffect } from "react";

const PagePresale = () => {

  useEffect(() => {
    window.location.href = "/#presale"
  }, []);

  return (
    <>
    </>
  );
};

export default PagePresale;
