import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from 'react-i18next';

import { Image } from "react-bootstrap";
import BtnConnect from "../account/BtnConnect";
import FundProcessing from "./FundProcessing";
import CountdownTimer from "./CountDownTimer";
import BuyOnETH from "./BuyOnETH";
import BuyOnBSC from "./BuyOnBSC";

import {
  getNumberEther,
  getNumberUSDT,
  numberWithCommas,
  roundNumberWithDecimal,
  getChainConnect,
  showNumberOrDash,
} from "../../utils/lib";

import { web3 as web3Utis } from "../../consts";
import * as actions from "../../actions";
import i18next from "i18next";

const PreSaleNStake = ({ currentRound, nextRound, handleCountdownZero }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [isBSC, setIsBSC] = useState(
    getChainConnect() === actions.BSC_NETWORK_NAME
  );

  const { presaleBalance } = useSelector(
    (state) => state.presaleReducer,
    shallowEqual
  );

  const totalOffchainBalance = () => {
    let balance = getNumberEther(_.get(presaleBalance, "balance", 0), true, 2);
    let pending = getNumberEther(
      _.get(presaleBalance, "balance_pending", 0),
      true,
      2
    );
    let total = balance + pending;
    // console.log({ balance, pending, total });
    if(!total) return null;
    return showNumberOrDash(total);
  };

  const handleSwitchNetwork = async (evt, value) => {
    setIsBSC(value);
    if (value) {
      console.log("Switch to BSC");
      await dispatch(actions.switchChain(actions.BSC_NETWORK_NAME));
    } else {
      console.log("Switch to ETH");
      await dispatch(actions.switchChain(actions.ETH_NETWORK_NAME));
    }
  };

  return (
    // PRESALE & STAKE
    <section
      id="presale"
      className="presale-stake-section text-center position-relative"
      data-bs-spy="scroll"
      data-bs-target="#presale"
      data-bs-offset="0"
      data-bs-smooth-scroll="true"
    >
      {/* Decoration */}
      <div className="card-polygon-decoration right"></div>
      <div className="card-polygon-decoration bottom"></div>
      <div className="container">
        <h1 className="display-3 text-uppercase">{t('presale_and_stake')}</h1>
        <div className="row mb-3">
          <div className="col-xl-11 mx-lg-auto">
            <h2 className="font-roboto">{t('presale_and_stake_des')}</h2>
          </div>
        </div>
        {/* Progressing */}
        <FundProcessing />

        {!isBSC ? (
          <>
            <BuyOnETH
              currentRound={currentRound}
              nextRound={nextRound}
              handleCountdownZero={handleCountdownZero}
            />
            {/* Buy on BSC */}
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-polygon-secondary"
                onClick={(evt) => handleSwitchNetwork(evt, true)}
              >
                <Image
                  src="/images/icons/ico-BSC.png"
                  alt="ico-bsc"
                  width="24"
                />
                <span className="ps-2">{t('buy_on_bsc')}</span>
              </button>
            </div>
          </>
        ) : (
          <>
            <BuyOnBSC
              currentRound={currentRound}
              nextRound={nextRound}
              handleCountdownZero={handleCountdownZero}
            />
            {/* Buy on BSC */}
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-polygon-secondary"
                onClick={(evt) => handleSwitchNetwork(evt, false)}
              >
                <Image
                  src="/images/icons/ico-ETH.png"
                  alt="ico-eth"
                  width="24"
                />
                <span className="ps-2">{t('buy_on_eth')}</span>
              </button>
            </div>
          </>
        )}
        <div>
          {totalOffchainBalance() && (
            <a type="button" className="btn btn-link" href="/staking">
              Avaiable: {totalOffchainBalance()} ZC. Go to [Claim & Stake]
            </a>
          )}
        </div>
        {/* F&Q */}
        <a type="button" className="btn btn-link" href="/#FAQ">
          {i18next.t('faq')}
        </a>
      </div>
    </section>
  );
};
export default PreSaleNStake;
