import Web3 from "web3";
import _ from "lodash";

import { ethers } from "ethers";

import { alertActions } from "./alert.actions";
import { web3Constants } from "../constants";
import getWeb3 from "../utils/getWeb3";

import ERC20ABI from "../_contracts/abi-erc20.json";
import ERC20ABI_LUS from "../_contracts/zc/ZC.json";

import ABI_ZC_STAKE from "../_contracts/zc/ZCStake.json";
import ABI_ZC_PreSale from "../_contracts/zc/ZCPreSale.json";

import { getNumberEther, getNumberUSDT } from "../utils/lib";
import { loadPresaleBalance } from "./presale.actions";
// import ERC20ABI_HLUS from '../_contracts/LUSStakingVip.json';

const TOKEN_USDT_ETH = process.env.REACT_APP_TOKEN_USDT_ETH;
const TOKEN_USDT_BSC = process.env.REACT_APP_TOKEN_USDT_BSC;
const TOKEN_LUS = process.env.REACT_APP_TOKEN_LUS;

export const ETH_NETWORK_NAME = process.env.REACT_APP_NETWORK_ETH_NAME;
export const BSC_NETWORK_NAME = process.env.REACT_APP_NETWORK_BSC_NAME;

const SMC_ADDRESS_STAKE = process.env.REACT_APP_TOKEN_STAKING_ETH;
const SMC_ADDRESS_PRESALE_ETH = process.env.REACT_APP_TOKEN_PRESALE_ETH;
const SMC_ADDRESS_PRESALE_BSC = process.env.REACT_APP_TOKEN_PRESALE_BSC;

const networks = {
  bsc_testnet: {
    chainId: `0x${Number(97).toString(16)}`, // A 0x-prefixed hexadecimal string
    chainName: "Binance Smart Chain Testnet",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "tBNB", // 2-6 characters long
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.bnbchain.org:8545"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  bsc: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: "Binance Smart Chain Mainnet",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed1.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  goerli: {
    chainId: `0x${Number(5).toString(16)}`,
    chainName: "Goerli ETH Testnet",
    nativeCurrency: {
      name: "tETH",
      symbol: "tETH",
      decimals: 18,
    },
    rpcUrls: ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://goerli.etherscan.io/"],
  },
  sepolia: {
    chainId: `0x${Number(11155111).toString(16)}`,
    chainName: "Sepolia",
    nativeCurrency: {
      name: "Sepolia Ether",
      symbol: "SEP",
      decimals: 18,
    },
    rpcUrls: ["https://sepolia.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://sepolia.etherscan.io/"],
  },
  eth: {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    // rpcUrls: ["https://powerful-powerful-bird.quiknode.pro/32835c5ea0d44e21ee2fbe361a64c431bcf07b51/"],
    rpcUrls: [
      "https://eth.zapclash.com/32835c5ea0d44e21ee2fbe361a64c431bcf07b51",
    ],
    blockExplorerUrls: ["https://etherscan.io/"],
  },
};

// async function isMetaMaskConnected() {
//     const { ethereum } = window;
//     const accounts = await ethereum.request({ method: 'eth_accounts' });
//     return accounts && accounts.length > 0;
// }

export const initWeb3 = () => async (dispatch, getState) => {
  const eth_provider = _.get(networks, `${ETH_NETWORK_NAME}.rpcUrls.0`);
  const bsc_provider = _.get(networks, `${BSC_NETWORK_NAME}.rpcUrls.0`);

  console.log({ eth_provider, bsc_provider });

  const web3_eth_provider = new Web3.providers.HttpProvider(eth_provider);
  const web3_eth = new Web3(web3_eth_provider);

  const web3_bsc_provider = new Web3.providers.HttpProvider(bsc_provider);
  const web3_bsc = new Web3(web3_bsc_provider);

  const smc_stake = new web3_eth.eth.Contract(ABI_ZC_STAKE, SMC_ADDRESS_STAKE);
  const smc_presale = new web3_eth.eth.Contract(
    ABI_ZC_PreSale,
    SMC_ADDRESS_PRESALE_ETH
  );

  dispatch({
    type: web3Constants.WEB3_INIT,
    web3_eth,
    smc_stake,
    smc_presale,
    web3_bsc,
  });
};

async function checkChainSwitch(web3, _chainID) {
  const chainIdDec = await web3.eth.getChainId();
  console.log({ _chainID, chainIdDec });
  return _chainID === chainIdDec.toString(16);
}

export const web3Connect = (isLogin) => async (dispatch, getState) => {
  const web3 = await getWeb3();
  const state = getState();
  const { web3_eth, chain_connect } = state.web3;
  const key_chain_connect = process.env.REACT_APP_KEY_CHAIN_CONNECT;
  console.log({ key_chain_connect });
  let chain_use = localStorage.getItem(key_chain_connect) || chain_connect;
  console.log({ chain_use });

  if (web3.givenProvider === null) {
    if (isLogin) {
      dispatch(alertActions.error("Please connect to MetaMask!"));
    }
    return web3;
  }

  let account;
  const chainID = _.get(networks, `${chain_use}.chainId`).replace("0x", "");

  const chainActived = await checkChainSwitch(web3, chainID);

  // if (window.ethereum && chainActived === false) {
  //   await window.ethereum.request({
  //     method: "wallet_addEthereumChain",
  //     params: [
  //       {
  //         ...networks[chain_use],
  //       },
  //     ],
  //   });
  //   console.log("web3Connect #1");
  //   return account;
  // }

  // imposition login when khi pruchase and buy
  if (isLogin && !chainActived) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainID}` }],
      });
    } catch (error) {
      if (error.code === 4001) {
        dispatch(alertActions.error(error.message));
      }
      if (error.code === 4902) {
        dispatch(alertActions.error("Unrecognized chain ID " + chainID));
      }
    }
    console.log("web3Connect #2");
    return account;
  }

  // Acccounts now exposed
  let checkConected = [];
  let _acc = localStorage.getItem("_acc");
  if (window.ethereum && isLogin) {
    await window.ethereum.request({
      method: "wallet_requestPermissions",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });

    checkConected = await web3.eth.getAccounts();

    if (checkConected) {
      _acc = checkConected[0];
      localStorage.setItem("_acc", checkConected[0]);
    }
  }

  if (_acc) {
    try {
      checkConected = await web3.eth.getAccounts();
    } catch (error) {
      if (isLogin) {
        dispatch(alertActions.error("Please connect to MetaMask!"));
      }
      console.log("web3Connect #3");
      return account;
    }
  }

  // account conected when mart chain dapp
  if (checkConected.length > 0 && chainActived === false) {
    try {
      // await window.ethereum.request({
      //   method: "wallet_addEthereumChain",
      //   params: [
      //     {
      //       ...networks[chain_use],
      //     },
      //   ],
      // });
      // await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x61' }] })
    } catch (error) {
      if (error.code === 4001) {
        dispatch(alertActions.error(error.message));
      }

      if (error.code === -32603) {
        dispatch(alertActions.error("Not found network chainId " + chainID));
      }
    }
    console.log("web3Connect #4");
    return account;
  }

  // No account access
  if (checkConected.length === 0 && isLogin === false) {
    dispatch({
      type: web3Constants.WEB3_CONNECT,
      web3,
      account,
    });

    console.log("web3Connect #5");
    return account;
  }

  let checkAccount;

  try {
    // connect wallet
    checkAccount = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
  } catch (e) {
    if (e.code === 4001) {
      dispatch(alertActions.error(e.message));
    }

    console.log("web3Connect #6");
    return account;
  }

  if (checkAccount) {
    try {
      const accounts = await web3.eth.getAccounts();

      if (accounts && accounts.length > 0) {
        account = accounts[0];
      }
    } catch (error) {
      console.log("------------web3Connect getAccounts error", error);
    }

    dispatch({
      type: web3Constants.WEB3_CONNECT,
      web3,
      account,
    });

    if (account) {
      localStorage.setItem(process.env.REACT_APP_KEY_IS_CONNECTED, "1");
      dispatch(initBalanceETH());
      dispatch(initUSDTContract());
      if (chain_connect == ETH_NETWORK_NAME) {
        dispatch(initAccountStake());
      }
      dispatch(loadPresaleBalance());
    }
    return web3;
  }
};

export const web3Disconnect = () => async (dispatch, getState) => {
  const state = getState();

  const { web3 } = state.web3;

  localStorage.removeItem("_acc");

  let amountProcessable = 0;

  // await window.web3.eth.currentProvider.disconnect();

  dispatch({
    type: web3Constants.WEB3_DISCONNECT,
    web3: null,
    account: null,
  });

  // dispatch({
  //   type: web3Constants.INIT_CONTRACT_BUSD,
  //   contractBusd: null,
  //   balance: 0,
  // });

  // dispatch({
  //   type: web3Constants.INIT_CONTRACT_LUS,
  //   contractLus: null,
  //   balance: 0,
  // });

  // dispatch({
  //   type: stakingVipConstants.GET_ACCOUNT_STAKED_VIP,
  //   amountProcessable,
  // });
};

export const switchChain = (chain_connect) => async (dispatch, getState) => {
  localStorage.setItem(process.env.REACT_APP_KEY_CHAIN_CONNECT, chain_connect);
  dispatch({
    type: web3Constants.WEB3_SWITCH_CHAIN,
    chain_connect,
  });
  dispatch(web3Disconnect());
  dispatch(web3Connect(true));
};

export const initUSDTContract = () => async (dispatch, getState) => {
  const state = getState();

  const { web3, account, chain_connect } = state.web3;
  console.log({ web3, account });
  if (web3 && account) {
    let usdt_smc_address = TOKEN_USDT_ETH;
    if (chain_connect == BSC_NETWORK_NAME) {
      usdt_smc_address = TOKEN_USDT_BSC;
    }
    let contractUSDT = new web3.eth.Contract(ERC20ABI, usdt_smc_address);

    let balance = 0;
    if (contractUSDT && account) {
      const balanceBigN = await contractUSDT.methods.balanceOf(account).call();
      balance = balanceBigN;
      console.log({ balanceBigN, balance });
    }

    dispatch({
      type: web3Constants.INIT_CONTRACT_USDT,
      contractUSDT,
      balance,
    });
  }
};

export const initBalanceETH = () => async (dispatch, getState) => {
  const state = getState();

  const { web3, account, chain_connect } = state.web3;
  console.log({ web3, account });
  if (account) {
    // Get the balance ETH
    web3.eth.getBalance(account, (err, balance) => {
      if (err) {
        console.error("Error getting balance:", err);
      } else {
        // Convert the balance from Wei to Ether
        console.log(
          `Balance of ${account}: ${getNumberEther(balance, true, 5)} ETH`
        );
        dispatch({
          type: web3Constants.WEB3_INIT_BALANCE,
          balance_eth: getNumberEther(balance, true, 5),
        });
      }
    });
  }
};

export const initAccountStake = () => async (dispatch, getState) => {
  const state = getState();

  const { web3, account } = state.web3;
  if (web3 && account) {
    let contractAccount = new web3.eth.Contract(
      ABI_ZC_STAKE,
      SMC_ADDRESS_STAKE
    );

    const stakeInfo = await contractAccount.methods
      .getStakeInfo(account)
      .call();

    let [
      initialStake,
      stakeBalance,
      stakeAt,
      unstakeBalance,
      unstakeAt,
      restakeBalance,
      peddingEndLockAt,
      estimatedYield,
      initialYield,
    ] = stakeInfo;

    dispatch({
      type: web3Constants.WEB3_INIT_STAKE_ACCOUNT,
      contractAccount,
      stakeInfo: {
        initialStake,
        stakeBalance,
        stakeAt,
        unstakeBalance,
        unstakeAt,
        restakeBalance,
        peddingEndLockAt,
        estimatedYield,
        initialYield,
      },
    });
  }
};
