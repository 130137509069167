import i18next from "i18next";
import React from "react";

const Tokennomic = () => {
  return (
    // TOKENOMIC
    <section
      id="tokenomic"
      className="tokenomic-section position-relative"
      data-bs-spy="scroll"
      data-bs-target="#tokenomic"
      data-bs-offset="0"
      data-bs-smooth-scroll="true"
    >
      {/* Decoration */}
      <div className="card-polygon-decoration top"></div>
      <div className="card-polygon-decoration right"></div>
      <div className="container">
        <h1 className="display-3 text-uppercase text-white text-center">
          Tokenomic
        </h1>
        <div className="row">
          <div className="col mb-lg-0 mb-4 text-center">
            <div
              className="ico-tokenomic mx-auto"
              style={{
                backgroundImage: `url("/images/tokenomic/token/15-final.png")`,
              }}
            >
              &nbsp;
            </div>
          </div>
          <div className="col-lg-5">
            <ul className="list-group list-group-polygon">
              {/*Presale*/}
              <li className="list-group-item">
                <div className="card">
                  <div
                    className="card-header text-uppercase"
                    style={{
                      backgroundImage: `url("/images/tokenomic/token/1.png")`,
                    }}
                  >
                    Presale 4%
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="text-white">100% on TGE</span>
                    </li>
                  </ul>
                </div>
              </li>
              {/*Dex*/}
              <li className="list-group-item">
                <div className="card">
                  <div
                    className="card-header text-uppercase"
                    style={{
                      backgroundImage: `url("/images/tokenomic/token/5.png")`,
                    }}
                  >
                    Public sale 7%
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="text-white">
                        33% on TGE, cliff 1 month, then 33% monthly unlock
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
              {/*Community Rewards*/}
              <li className="list-group-item">
                <div className="card">
                  <div
                    className="card-header text-uppercase"
                    style={{
                      backgroundImage: `url("/images/tokenomic/token/8.png")`,
                    }}
                  >
                    Liquidity 7%
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="text-white">
                        20% on TGE, then 3% monthly unlock
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
              {/*Stalking & Rewards*/}
              <li className="list-group-item">
                <div className="card">
                  <div
                    className="card-header text-uppercase"
                    style={{
                      backgroundImage: `url("/images/tokenomic/token/10.png")`,
                    }}
                  >
                    Marketing 7%
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="text-white">
                        Cliff 1 month, then 2% monthly unlock
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="list-group-item">
                <div className="card">
                  <div
                    className="card-header text-uppercase"
                    style={{
                      backgroundImage: `url("/images/tokenomic/token/6.png")`,
                    }}
                  >
                    Team & Advisor 15%
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="text-white">
                        Cliff 12 months, then 5% monthly unlock
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="list-group-item">
                <div className="card">
                  <div
                    className="card-header text-uppercase"
                    style={{
                      backgroundImage: `url("/images/tokenomic/token/20.png")`,
                    }}
                  >
                    Staking 20%
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="text-white">
                        1% on TGE, then 1% monthly unlock
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="list-group-item">
                <div className="card">
                  <div
                    className="card-header text-uppercase"
                    style={{
                      backgroundImage: `url("/images/tokenomic/token/50.png")`,
                    }}
                  >
                    Play to Earn reward 40%
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <span className="text-white">
                        5% on Game Launch, then 2% monthly unlock
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Tokennomic;
