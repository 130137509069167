
// import '../assets/styles/error.scss';

const Page404 = () => {
    return (
        <div className="wrap-content">
            <div className="number">404</div>
            <div className="text"><span>Ooops...</span><br />page not found</div>
        </div>
    )
}

export default Page404;