import queryString from "query-string";
import { web3 as web3Utis } from "../consts";
import { presaleConstants } from "../constants";
import { web3Constants } from "../constants";

import { alertActions } from "./alert.actions";
import ABI_PRESALE from "../_contracts/zc/ZCPreSale.json";

// import { web3Connect } from "./web3.actions";
import { formatUriSecure, getNumberEther, getNumberUSDT } from "../utils/lib";
import { ceil } from "lodash";

export const SMC_STAKE_ETH = process.env.REACT_APP_TOKEN_STAKING_ETH;

export const loadStakeLeaderBoard = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: presaleConstants.FETCH_PRESALE_REQUEST,
    });

    // https://api-staging.lunarush.org/v1/market_place_api/api/boxes/market
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/presale/stats`;

    const response = await fetch(url);
    const responseBody = await response.json();
    const { data } = responseBody;
    console.log(data);
    dispatch({
      type: presaleConstants.FETCH_PRESALE_SUCCESS,
      data: data || {},
    });
  } catch (error) {
    dispatch({
      type: presaleConstants.FETCH_PRESALE_ERROR,
      message: error,
    });
  }
};

export const loadStakeStats = () => async (dispatch, getState) => {
  try {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/api/stake/stats?stake_address=${SMC_STAKE_ETH}`;

    console.log({ url });

    const response = await fetch(url);
    const responseBody = await response.json();
    const { data } = responseBody;
    console.log(data);
    dispatch({
      type: web3Constants.FETCH_STAKE_STATS_SUCCESS,
      stakeStats: data || {},
    });
  } catch (error) {
    dispatch({
      type: web3Constants.FETCH_STAKE_STATS_ERROR,
      message: error,
    });
  }
};

export const loadStakeLeaderboard = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { web3, account } = state.web3;
    const querySearch = {
      page: 1,
      page_size: 10,
      wallet_address: account || "",
      stake_address: SMC_STAKE_ETH,
    };

    const linkQuery = queryString.stringify(querySearch);
    const url = `${process.env.REACT_APP_API_ENDPOINT}/v1/api/leaderboard?${linkQuery}`;

    const response = await fetch(url);
    const responseBody = await response.json();
    const { data } = responseBody;
    console.log(data);
    dispatch({
      type: web3Constants.FETCH_STAKE_LEADERBOARD_SUCCESS,
      data: data || {},
    });
  } catch (error) {
    dispatch({
      type: web3Constants.FETCH_STAKE_LEADERBOARD_ERROR,
      message: error,
    });
  }
};

export const loadStakeConfig = () => async (dispatch, getState) => {
  const state = getState();

  const { smc_stake, account } = state.web3;
  if (smc_stake) {
    try {
      const stake_config = await smc_stake.methods.getConfig().call();
      console.log({ stake_config });
      dispatch({
        type: web3Constants.WEB3_INIT_STAKE_CONFIG,
        config: stake_config,
      });
    } catch (error) {
      console.log("---error loadStakeConfig", error);

      // dispatch({
      //   type: presaleConstants.FETCH_PRESALE_ROUND_INFO_SUCCESS,
      //   message: error,
      // });
      return error;
    }
  }
};
