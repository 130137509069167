import React, { useState, useEffect } from "react";
import i18next from "i18next";

import { calculateTimeLeft } from "../../utils/lib";


const CountdownTimer = ({ targetDate, onCountdownZero }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft(targetDate);
      setTimeLeft(newTimeLeft);

      if (
        newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === 0 &&
        newTimeLeft.seconds === 0
      ) {
        // Countdown reached zero, trigger the callback
        if (onCountdownZero) {
          onCountdownZero();
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      {/* <div>
      {timeLeft.days} days {timeLeft.hours} hours {timeLeft.minutes} minutes{" "}
      {timeLeft.seconds} seconds
    </div> */}
      {/* Count Down */}
      <div className="row col-xxl-6 col-lg-8 mb-3 mx-auto">
        {/* Days */}
        <div className="col">
          <div className="card card-squares card-squares-md">
            <div className="card-body">
              <h1 className="mb-0 text-body">{timeLeft.days}</h1>
              <small className="text-uppercase text-body">{i18next.t("days")}</small>
            </div>
          </div>
        </div>
        {/* Hours */}
        <div className="col">
          <div className="card card-squares card-squares-md">
            <div className="card-body">
              <h1 className="mb-0 text-body">{timeLeft.hours}</h1>
              <small className="text-uppercase text-body">{i18next.t("hours")}</small>
            </div>
          </div>
        </div>
        {/* Minutes */}
        <div className="col">
          <div className="card card-squares card-squares-md">
            <div className="card-body">
              <h1 className="mb-0 text-body">{timeLeft.minutes}</h1>
              <small className="text-uppercase text-body">{i18next.t("minutes")}</small>
            </div>
          </div>
        </div>
        {/* Seconds */}
        <div className="col">
          <div className="card card-squares card-squares-md">
            <div className="card-body">
              <h1 className="mb-0 text-body">{timeLeft.seconds}</h1>
              <small className="text-uppercase text-body">{i18next.t("seconds")}</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountdownTimer;
