import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ReactGA from "react-ga";

import { history } from "./_helpers";

import MainLayout from "./layouts/MainLayout";

// ZC
import Page404 from "./pages/Page404";
import HomePage from "./pages/HomePage";
import PageStaking from "./pages/PageStaking";
import PagePresale from "./pages/PagePresale";

// ReactGA.initialize('G-2CSFPRJSK5');
// ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  return (
    <Routes history={history}>
      <Route path="/" element={<MainLayout />}>
        {/* <Route path="/" element={<Navigate to="/marketplace" />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/staking" element={<PageStaking />} />
        <Route path="/presale" element={<PagePresale />} />
        {/* <Route path="staking">
          <Route path="account" element={<StakingPage />} />
          <Route path="leaderboard" element={<StakingLeaderbroardPage />} />
          <Route path="information" element={<StakingInfomationPage />} />
          <Route path="*" index element={<StakingPage />} />
        </Route> */}
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default App;
