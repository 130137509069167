import _ from "lodash";
import i18next from "i18next";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Image } from "react-bootstrap";

import i18n from "../../translation";
import { web3Constants } from "../../constants";
import languages from "../../locales/languages.json";

import * as Consts from "../../consts";

const Header = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.web3, shallowEqual);

  const changeLanguage = (lng) => {
    Consts.trackingEventGA(Consts.GA_EVENTS.btn_change_language);
    console.log(`Change language to ${lng}`);
    i18n.changeLanguage(lng);
    window.location.reload();
    // dispatch({
    //   type: web3Constants.SWITCH_LANGUAGE,
    //   language: lng,
    // });
  };

  const itemLanguage = () => {
    const defaultLanguage = _.find(languages, { code: "en" });
    let lang = language || i18n.language;
    return _.find(languages, { code: lang }) || defaultLanguage;
  };

  return (
    // <!-- Main Header -->
    <header className="navbar navbar-expand-lg navbar-togglable fixed-top">
      <div className="container-fluid">
        <a href="/" className="navbar-logo">
          &nbsp;
        </a>
        {/* Navbar toggler */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasMenu"
          aria-controls="offcanvasMenu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            className="bi"
            fill="white"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            ></path>
          </svg>
        </button>

        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasMenu"
          aria-labelledby="offcanvasMenuLabel"
        >
          <div className="offcanvas-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            <ul className="navbar-nav my-1 py-2">
              <li className="nav-item mb-1">
                <a
                  className="nav-link"
                  // href="/#presale"
                  onClick={() => {
                    Consts.handlerClickURL(
                      Consts.GA_EVENTS.btn_menu_presale,
                      "/#presale"
                      // true
                    );
                  }}
                >
                  {i18next.t("presale")}
                </a>
              </li>
              <li className="nav-item mb-1">
                <a
                  className="nav-link"
                  // href="/#gameplay"
                  onClick={() => {
                    Consts.handlerClickURL(
                      Consts.GA_EVENTS.btn_menu_gameplay,
                      "/#gameplay"
                      // true
                    );
                  }}
                >
                  {i18next.t("gameplay")}
                </a>
              </li>
              <li className="nav-item mb-1">
                <a
                  className="nav-link"
                  // href="/#roadmap"
                  onClick={() => {
                    Consts.handlerClickURL(
                      Consts.GA_EVENTS.btn_menu_roadmap,
                      "/#roadmap"
                      // true
                    );
                  }}
                >
                  {i18next.t("roadmap")}
                </a>
              </li>
              <li className="nav-item mb-1">
                <a
                  className="nav-link"
                  // href="/#tokenomic"
                  onClick={() => {
                    Consts.handlerClickURL(
                      Consts.GA_EVENTS.btn_menu_tokenomic,
                      "/#tokenomic"
                      // true
                    );
                  }}
                >
                  {i18next.t("tokenomic")}
                </a>
              </li>

              <li className="nav-item mb-2">
                <a
                  className="nav-link"
                  // href="/#team"
                  onClick={() => {
                    Consts.handlerClickURL(
                      Consts.GA_EVENTS.btn_menu_team,
                      "/#team"
                      // true
                    );
                  }}
                >
                  {i18next.t("team")}
                </a>
              </li>

              <li className="nav-item mb-2">
                <a className="nav-link d-flex align-items-center" href="#">
                  <div className="dropdown">
                    <Image
                      src={`${itemLanguage().flag}`}
                      alt={`icon-language-${itemLanguage().code}`}
                      className="me-3"
                      width={24}
                    />
                    <button
                      className="btn dropdown-toggle text-capitalize text-white"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {itemLanguage().name}
                    </button>
                    <ul className="dropdown-menu">
                      {languages
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((language) => (
                          <li key={language.code}>
                            <Image
                              src={`${language.flag}`}
                              alt={`icon-language-${language.code}`}
                              className="me-3"
                              width={24}
                            />
                            <button
                              className="btn p-0"
                              type="button"
                              onClick={() => changeLanguage(language.code)}
                            >
                              {language.name}
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </a>
              </li>

              <li className="nav-item mb-2">
                <button
                  onClick={() => {
                    Consts.handlerClickURL(
                      Consts.GA_EVENTS.btn_audit,
                      "https://github.com/Coinsult/solidity/blob/main/Coinsult_ZapClash_0x45...E6f0_Audit.pdf",
                      true
                    );
                  }}
                  // href="https://github.com/Coinsult/solidity/blob/main/Coinsult_ZapClash_0x45...E6f0_Audit.pdf"
                  // target="_blank"
                  className="btn btn-o"
                >
                  <span>{i18next.t("audit")}</span>
                </button>
              </li>
              <li className="nav-item mb-2">
                <button
                  onClick={() => {
                    Consts.handlerClickURL(
                      Consts.GA_EVENTS.btn_staking,
                      "/staking"
                    );
                  }}
                  className="btn btn-o"
                >
                  <span>{i18next.t("staking")}</span>
                </button>
              </li>
              <li className="nav-item">
                <button
                  // href="https://docs.zapclash.com/"
                  target="_blank"
                  className="btn btn-o"
                  onClick={() =>
                    Consts.handlerClickURL(
                      Consts.GA_EVENTS.btn_whitepaper,
                      "https://docs.zapclash.com/",
                      true
                    )
                  }
                >
                  <span>{i18next.t("whitepaper")}</span>
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* Navbar collapse */}
        <div
          className="navbar-collapse d-none d-lg-flex justify-content-between align-items-start collapse"
          id="navbarCollapse"
        >
          {/* Navbar nav */}
          <ul className="navbar-nav navbar-bar-left">
            <li className="nav-item">
              <a
                className="nav-link"
                // href="/#presale"
                onClick={() => {
                  Consts.handlerClickURL(
                    Consts.GA_EVENTS.btn_menu_presale,
                    "/#presale"
                    // true
                  );
                }}
              >
                {i18next.t("presale")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                // href="/#gameplay"
                onClick={() => {
                  Consts.handlerClickURL(
                    Consts.GA_EVENTS.btn_menu_gameplay,
                    "/#gameplay"
                    // true
                  );
                }}
              >
                {i18next.t("gameplay")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => {
                  Consts.handlerClickURL(
                    Consts.GA_EVENTS.btn_menu_roadmap,
                    "/#roadmap"
                    // true
                  );
                }}
              >
                {i18next.t("roadmap")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => {
                  Consts.handlerClickURL(
                    Consts.GA_EVENTS.btn_menu_tokenomic,
                    "/#tokenomic"
                    // true
                  );
                }}
              >
                {i18next.t("tokenomic")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={() => {
                  Consts.handlerClickURL(
                    Consts.GA_EVENTS.btn_menu_team,
                    "/#team"
                    // true
                  );
                }}
                // href="/#team"
              >
                {i18next.t("team")}
              </a>
            </li>
          </ul>

          {/* Navbar nav */}
          <ul className="navbar-nav navbar-bar-right">
            <li className="nav-item">
              <a className="nav-link d-flex align-items-center" href="#">
                <div className="dropdown">
                  <Image
                    src={`${itemLanguage().flag}`}
                    alt="icon-language-en"
                    className="me-3"
                    width={24}
                  />
                  <button
                    className="btn dropdown-toggle text-capitalize text-white"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {itemLanguage().name}
                  </button>
                  <ul className="dropdown-menu">
                    {languages
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((language) => (
                        <li key={language.code}>
                          <Image
                            src={`${language.flag}`}
                            alt={`icon-language-${language.code}`}
                            className="me-3"
                            width={24}
                          />
                          <button
                            className="btn p-0"
                            type="button"
                            onClick={() => changeLanguage(language.code)}
                          >
                            {language.name}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <button
                onClick={() => {
                  Consts.handlerClickURL(
                    Consts.GA_EVENTS.btn_audit,
                    "https://github.com/Coinsult/solidity/blob/main/Coinsult_ZapClash_0x45...E6f0_Audit.pdf",
                    true
                  );
                }}
                // href="https://github.com/Coinsult/solidity/blob/main/Coinsult_ZapClash_0x45...E6f0_Audit.pdf"
                // target="_blank"
                className="btn btn-o me-2"
              >
                <span>{i18next.t("audit")}</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                onClick={() => {
                  Consts.handlerClickURL(
                    Consts.GA_EVENTS.btn_staking,
                    "/staking"
                  );
                }}
                className="btn btn-o me-2"
              >
                <span>{i18next.t("staking")}</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                // href="https://docs.zapclash.com/"
                // target="_blank"
                onClick={() =>
                  Consts.handlerClickURL(
                    Consts.GA_EVENTS.btn_whitepaper,
                    "https://docs.zapclash.com/",
                    true
                  )
                }
                className="btn btn-o me-2"
              >
                <span>{i18next.t("whitepaper")}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
