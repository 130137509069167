import React from "react";
import i18next from "i18next";
import { isMobileOrTablet } from "../../utils/lib";

const TokenZC = () => {
  return (
    // TOKEN $ZC
    <section className="admin-token-section position-relative">
      {/* Decoration */}
      <div className="card-polygon-decoration right"></div>
      <div className="card-polygon-decoration bottom"></div>
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <video autoPlay loop muted playsInline poster="/video/coin.png">
              {!isMobileOrTablet() && (
                <source src="/video/video_coin.webm" type="video/webm" />
              )}
            </video>
          </div>
          <div className="col-lg-4 me-lg-5">
            <h1 className="display-3 text-uppercase text-white text-center text-lg-start">
              {i18next.t("token_zc")}
            </h1>
            <p className="fw-medium mb-4 text-white">
              {i18next.t("token_zc_des")}
            </p>
            <ul className="list-group list-group-polygon">
              {/*Total Supply*/}
              <li className="list-group-item">
                <div className="card">
                  <div className="card-header text-uppercase">
                    {i18next.t("total_supply")}
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <label
                          title=""
                          htmlFor="check_label"
                          className="form-check-label"
                        >
                          40,000,000
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              {/*Utilities*/}
              <li className="list-group-item">
                <div className="card">
                  <div className="card-header text-uppercase">
                    {i18next.t("utilities")}
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <label
                          title=""
                          htmlFor="check_label"
                          className="form-check-label"
                        >
                          {i18next.t("utilities_1")}
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <label
                          title=""
                          htmlFor="check_label"
                          className="form-check-label"
                        >
                          {i18next.t("utilities_2")}
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <label
                          title=""
                          htmlFor="check_label"
                          className="form-check-label"
                        >
                          {i18next.t("utilities_3")}
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <label
                          title=""
                          htmlFor="check_label"
                          className="form-check-label"
                        >
                          {i18next.t("utilities_4")}
                        </label>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          id="check_label"
                          className="form-check-input"
                          defaultChecked={true}
                        />
                        <label
                          title=""
                          htmlFor="check_label"
                          className="form-check-label"
                        >
                          {i18next.t("utilities_5")}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TokenZC;
