import i18next from "i18next";
import React from "react";

const RoadMap = () => {
  return (
    <>
      {/*ROADMAP*/}
      <section id="roadmap" className="roadmap-section position-relative"
               data-bs-spy="scroll" data-bs-target="#roadmap" data-bs-offset="0" data-bs-smooth-scroll="true"
      >
        {/* Decoration */}
        <div className="card-polygon-decoration top"></div>
        <div className="card-polygon-decoration right"></div>
        <div className="card-polygon-decoration left"></div>
        <div className="container">
          <h1 className="display-3 text-uppercase text-white text-center">
            {i18next.t("roadmap")}
          </h1>

          <div className="card card-transparent">
            <ul className="list-group list-group-horizontal">
              {/*Phase 1*/}
              <li className="list-group-item">
                <div className="card">
                  <div className="card-header">{i18next.t("phase")} 1</div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_1" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_1" className="form-check-label">
                            {i18next.t("phase_1_1")}
                          </label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_2" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_2" className="form-check-label">
                          {i18next.t("phase_1_2")}
                          </label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_3" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_3" className="form-check-label">
                          {i18next.t("phase_1_3")}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              {/*Phase 2*/}
              <li className="list-group-item">
                <div className="card">
                  <div className="card-header">{i18next.t("phase")} 2</div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_1" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_1" className="form-check-label">
                            {i18next.t("phase_2_1")}
                          </label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_2" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_2" className="form-check-label">{i18next.t("phase_2_2")}</label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_3" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_3" className="form-check-label">
                            {i18next.t("phase_2_3")}
                          </label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_3" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_3" className="form-check-label">
                            {i18next.t("phase_2_4")}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              {/*Phase 3*/}
              <li className="list-group-item">
                <div className="card">
                  <div className="card-header">{i18next.t("phase")} 3</div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_1" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_1" className="form-check-label">{i18next.t("phase_3_1")}</label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_2" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_2" className="form-check-label">{i18next.t("phase_3_2")}</label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_3" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_3" className="form-check-label">{i18next.t("phase_3_3")}</label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_3" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_3" className="form-check-label">{i18next.t("phase_3_4")}</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              {/*Phase 4*/}
              <li className="list-group-item">
                <div className="card">
                  <div className="card-header">{i18next.t("phase")} 4</div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_1" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_1" className="form-check-label">{i18next.t("phase_4_1")}</label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_2" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_2" className="form-check-label">{i18next.t("phase_4_2")}</label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_3" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_3" className="form-check-label">
                            {i18next.t("phase_4_3")}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              {/*Phase 5*/}
              <li className="list-group-item">
                <div className="card">
                  <div className="card-header">{i18next.t("phase")} 5</div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_1" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_1" className="form-check-label">{i18next.t("phase_5_1")}</label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_2" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_2" className="form-check-label">{i18next.t("phase_5_2")}</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              {/*Phase 6*/}
              <li className="list-group-item">
                <div className="card">
                  <div className="card-header">{i18next.t("phase")} 6</div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_2" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_2" className="form-check-label">{i18next.t("phase_6_1")}</label>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="form-check form-check-inline">
                          <input type="checkbox" id="check_label_3" className="form-check-input" defaultChecked={true} />
                          <label title="" htmlFor="check_label_3" className="form-check-label">
                            {i18next.t("phase_6_2")}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="d-none">
            <div className="row row-cols-1 row-cols-lg-3 mx-auto">
              {/*Phase 1*/}
              <div className="col">
                <div className="card card-phase card-phase-1">
                  <div className="card-header">Phase 1</div>
                  <div className="card-body">
                    <div className="card card-transparent">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Kickstart the Zap Clash project through a token
                              presale
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Launch of staking mechanism for presale token buyers
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Building community
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*Phase 2*/}
              <div className="col">
                <div className="card card-phase card-phase-2">
                  <div className="card-header">Phase 2</div>
                  <div className="card-body">
                    <div className="card card-transparent">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Launching NFT collection with card characters and
                              bases
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Marketplace
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Launches NFT reward mechanism for long-term $ZC
                              holders
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-3">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Listing DEX
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*Phase 3*/}
              <div className="col">
                <div className="card card-phase card-phase-3">
                  <div className="card-header">Phase 3</div>
                  <div className="card-body">
                    <div className="card card-transparent">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Offical Game Launch
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Start season 1 with the leaderboard system
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              P2P match system and betting mechanism according to
                              ZAP
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Introducing a token reward system to incentivize
                              community participation and foster sustainable
                              relationships
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-3 mx-auto">
              {/*Phase 4*/}
              <div className="col">
                <div className="card card-phase card-phase-4">
                  <div className="card-header">Phase 4</div>
                  <div className="card-body">
                    <div className="card card-transparent">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Expanding the platform and attracting more users: We
                              will be launching on both IOS and Android mobile
                              operating systems
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Improved game system
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Add more cards and bases
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*Phase 5*/}
              <div className="col">
                <div className="card card-phase card-phase-5">
                  <div className="card-header">Phase 5</div>
                  <div className="card-body">
                    <div className="card card-transparent">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              The world's first gamefi Esports tournament is
                              organized with the aim of promoting and introducing
                              the image of Zap Clash to a global player audience
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Streaming System
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*Phase 6*/}
              <div className="col">
                <div className="card card-phase card-phase-6">
                  <div className="card-header">Phase 6</div>
                  <div className="card-body">
                    <div className="card card-transparent">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Launching a blockchain card gamefi platform on the
                              Ethereum network
                            </label>
                          </div>
                        </li>
                        <li className="list-group-item mb-2">
                          <div className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                id="check_label"
                                className="form-check-input"
                                defaultChecked={true}
                            />
                            <label
                                title=""
                                htmlFor="check_label"
                                className="form-check-label"
                            >
                              Developing the Zap Clash ecosystem
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default RoadMap;
