export const web3Constants = {
  WEB3_CONNECT: "WEB3_CONNECT",
  WEB3_DISCONNECT: "WEB3_DISCONNECT",
  WEB3_GET_ACCOUNT: "WEB3_GET_ACCOUNT",

  INIT_CONTRACT_TOKEN: "INIT_CONTRACT_TOKEN",
  INIT_CONTRACT_PRESALE: "INIT_CONTRACT_PRESALE",
  INIT_CONTRACT_STAKING: "INIT_CONTRACT_STAKING",

  INIT_CONTRACT_USDT: "INIT_CONTRACT_USDT",

  WEB3_INIT: "WEB3_INIT",
  WEB3_INIT_BALANCE: "WEB3_INIT_BALANCE",
  WEB3_SWITCH_CHAIN: "WEB3_SWITCH_CHAIN",
  SWITCH_LANGUAGE: "SWITCH_LANGUAGE",

  WEB3_INIT_STAKE_CONFIG: "WEB3_INIT_STAKE_CONFIG",
  WEB3_INIT_STAKE_ACCOUNT: "WEB3_INIT_STAKE_ACCOUNT",
  WEB3_GET_STAKE_INFO: "WEB3_GET_STAKE_INFO",

  FETCH_STAKE_STATS_REQUEST: "FETCH_STAKE_STATS_REQUEST",
  FETCH_STAKE_STATS_SUCCESS: "FETCH_STAKE_STATS_SUCCESS",
  FETCH_STAKE_STATS_ERROR: "FETCH_STAKE_STATS_ERROR",

  FETCH_STAKE_LEADERBOARD_REQUEST: "FETCH_STAKE_LEADERBOARD_REQUEST",
  FETCH_STAKE_LEADERBOARD_SUCCESS: "FETCH_STAKE_LEADERBOARD_SUCCESS",
  FETCH_STAKE_LEADERBOARD_ERROR: "FETCH_STAKE_LEADERBOARD_ERROR",

  UPDATE_STEP_BUY_IN_ETH: "UPDATE_STEP_BUY_IN_ETH",
};
