export const presaleConstants = {
  FETCH_PRESALE_REQUEST: "FETCH_PRESALE_REQUEST",
  FETCH_PRESALE_SUCCESS: "FETCH_PRESALE_SUCCESS",
  FETCH_PRESALE_ERROR: "FETCH_PRESALE_ERROR",

  FETCH_PRESALE_ROUND_INFO_REQUEST: "FETCH_PRESALE_ROUND_INFO_REQUEST",
  FETCH_PRESALE_ROUND_INFO_SUCCESS: "FETCH_PRESALE_ROUND_INFO_SUCCESS",
  FETCH_PRESALE_ROUND_INFO_ERROR: "FETCH_PRESALE_ROUND_INFO_ERROR",

  FETCH_PRESALE_BALANCE_REQUEST: "FETCH_PRESALE_BALANCE_REQUEST",
  FETCH_PRESALE_BALANCE_SUCCESS: "FETCH_PRESALE_BALANCE_SUCCESS",
  FETCH_PRESALE_BALANCE_ERROR: "FETCH_PRESALE_BALANCE_ERROR",

  W3_PRESALE_BSC_SUCCESS: "W3_PRESALE_BSC_SUCCESS",
  W3_PRESALE_BSC_ERROR: "W3_PRESALE_BSC_ERROR",
};
