import { combineReducers } from "redux";
import { destroyConstants } from "../constants";

/* AUTH */
import { alert } from "./alert.reducer";
import { web3 } from "./web3.reducer";

// PreSale
import { presaleReducer } from "./presale.reducer";
import { w3PreSale } from "./w3PreSale.reducer";
import { w3Usdt } from "./w3Usdt.reducer";
import { w3Stake } from "./w3Stake.reducer";
import { buyOnETH } from "./buyOnETH.reducer";

const appReducer = combineReducers({
  alert,
  web3,
  w3PreSale,
  w3Usdt,
  w3Stake,

  // PreSale
  presaleReducer,
  buyOnETH,
});

const rootReducer = (state, action) => {
  if (action.type === destroyConstants.DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
